<template lang="pug">
.modal(@click="cancel")
  .inner(@click.stop)
    section
      .header Origineel totaalbedrag:
      div {{ priceStr(original) }}

    section
      .header Nieuw totaalbedrag:
      div {{ priceStr(newPrice) }}
      input.add(type="button", value="-10", @click="add(-1000)")
      input.add(type="button", value="-5", @click="add(-500)")
      input.add(type="button", value="-1", @click="add(-100)")
      input.add(type="button", value="+1", @click="add(100)")
      input.add(type="button", value="+5", @click="add(500)")
      input.add(type="button", value="+10", @click="add(1000)")

    section
      .header Opwaarderen met:
      div {{ priceStr(amount) }}

    .buttons
      input(@click="cancel()", type="button", value="Annuleer")
      input(@click="confirm()", type="button", value="Volgende")
</template>

<script setup lang="ts">
import { priceStr } from "../../scripts/util";
import { computed, ref } from "vue";

interface TopupModalProps {
  original: number;
}

interface TopupModalEmits {
  (e: "cancel"): void;
  (e: "confirm", amount: number): void;
}

const props = defineProps<TopupModalProps>();
const emit = defineEmits<TopupModalEmits>();
const amount = ref(0);
const newPrice = computed({
  get: () => {
    return props.original + amount.value;
  },
  set(value: number) {
    amount.value = Math.max(value - props.original, 0);
  },
});

function cancel() {
  emit("cancel");
}

function confirm() {
  emit("confirm", amount.value);
}

function add(amount: number) {
  newPrice.value = Math.floor(newPrice.value / amount + 1) * amount;
}
</script>

<style lang="sass" scoped>
@import "../../styles/colors"
@import "../../styles/modal"

.add
    background-color: $flat-blue-3
    color: #ffffff
    font-size: 1em
    border-radius: 0

    height: 40px
    width: 50px
    padding: 0
    border: 1px solid white

    -webkit-appearance: none
    cursor: pointer

    margin: 2px

.header
    font-size: 1.3em
    color: $flat-blue-3

section
    margin: 12px 16px

    div
        margin: 4px 0
</style>
