import * as IbanTools from "ibantools";

export function formatIban(iban: string) {
  return IbanTools.friendlyFormatIBAN(iban);
}

export function getBicFromIban(iban: string) {
  const { bban, valid } = IbanTools.extractIBAN(iban);

  if (!valid || !bban) {
    return;
  }

  const bank = bban.toUpperCase().substring(0, 4);

  if (bank in KNOWN_BICS) {
    return KNOWN_BICS[bank];
  }
}

export function isIbanValid(iban: string) {
  return IbanTools.extractIBAN(iban).valid;
}

export function isBicValid(bic: string) {
  return IbanTools.extractBIC(bic).valid;
}

// From https://www.betaalvereniging.nl/wp-content/uploads/BIC-lijst-NL.xlsx (accessed 22-01-2023)
export const KNOWN_BICS: Readonly<{ [bank: string]: string }> = {
  ABNA: "ABNANL2A",
  ABNC: "ABNCNL2A",
  ADYB: "ADYBNL2A",
  AEGO: "AEGONL2U",
  ANDL: "ANDLNL2A",
  ARBN: "ARBNNL22",
  ARSN: "ARSNNL21",
  ASNB: "ASNBNL21",
  ATBA: "ATBANL2A",
  BARC: "BARCNL22",
  BCIT: "BCITNL2A",
  BICK: "BICKNL2A",
  BINK: "BINKNL21",
  BITS: "BITSNL2A",
  BKCH: "BKCHNL2R",
  BKMG: "BKMGNL2A",
  BLGW: "BLGWNL21",
  BNDA: "BNDANL2A",
  BNGH: "BNGHNL2G",
  BNPA: "BNPANL2A",
  BOFA: "BOFANLNX",
  BOFS: "BOFSNL21002",
  BOTK: "BOTKNL2X",
  BUNQ: "BUNQNL2A",
  CHAS: "CHASNL2X",
  CITC: "CITCNL2A",
  CITI: "CITINL2X",
  COBA: "COBANL2X",
  CCBV: "CCBVNL2A",
  DELE: "DELENL22",
  DEUT: "DEUTNL2A",
  DHBN: "DHBNNL2R",
  DLBK: "DLBKNL2A",
  DNIB: "DNIBNL2G",
  EBPB: "EBPBNL22",
  EBUR: "EBURNL21",
  FBHL: "FBHLNL2A",
  FLOR: "FLORNL2A",
  FNOM: "FNOMNL22",
  FRNX: "FRNXNL2A",
  FVLB: "FVLBNL22",
  FXBB: "FXBBNL22",
  GILL: "GILLNL2A",
  HAND: "HANDNL2A",
  HHBA: "HHBANL22",
  HIFX: "HIFXNL2A",
  HSBC: "HSBCNL2A",
  ICBC: "ICBCNL2A",
  ICBK: "ICBKNL2A",
  ICEP: "ICEPNL21",
  INGB: "INGBNL2A",
  ISAE: "ISAENL2A",
  ISBK: "ISBKNL2A",
  KABA: "KABANL2A",
  KNAB: "KNABNL2H",
  KOEX: "KOEXNL2A",
  KRED: "KREDNL2X",
  LOYD: "LOYDNL2A",
  LPLN: "LPLNNL2F",
  MHCB: "MHCBNL2A",
  MODR: "MODRNL22",
  NNBA: "NNBANL2G",
  NWAB: "NWABNL2G",
  PCBC: "PCBCNL2A",
  RABO: "RABONL2U",
  RBRB: "RBRBNL21",
  SNSB: "SNSBNL2A",
  SOGE: "SOGENL2A",
  TRIO: "TRIONL2U",
  UGBI: "UGBINL2A",
  VOWA: "VOWANL21",
  VPAY: "VPAYNL22",
  VTPS: "VTPSNL2R",
  ZWLB: "ZWLBNL21",
  BCDM: "BCDMNL22",
  BMEU: "BMEUNL21",
  REVO: "REVONL22",
};
