import { Api, ApiResponse } from "./api";

export class LoginResponse {
  access_token: string;
}

export class LoginApi extends Api {
  public async login(
    username: string,
    password: string
  ): Promise<ApiResponse<LoginResponse>> {
    return this.post<LoginResponse>("/api/login/", {
      password: password,
      username: username.toLowerCase().trim(),
    });
  }

  public async oauth(code: string): Promise<ApiResponse<LoginResponse>> {
    return this.post<LoginResponse>("/api/login/oauth/viaduct/", {
      code: code,
    });
  }
}

export const loginApi = new LoginApi();
