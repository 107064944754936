<template lang="pug">
.modal(@click="cancel()")
  .inner(@click.stop)
    .title {{ title }}
    .title
      small(v-if="isAprilFools") Happy April fools' day!

    input(
      type="password",
      v-model="pin",
      placeholder="PIN-code",
      :class="{ shake: pinError }"
    )

    .pin-error(:class="{ shown: pinError }") Invalid passcode

    .keypad
      .keypad__key(v-for="index in numbers", @click="addDigit(index)")
        | {{ index }}

      .keypad__key.keypad__special.keypad__backspace(@click="backspace()")

      .keypad__key(@click="addDigit(0)")
        | 0

      .keypad__key.keypad__special

    .buttons
      input(@click="cancel()", type="button", value="Annuleer")
      input(
        @click="pin.length === 4 && confirm()",
        type="button",
        value="Gereed"
      )
</template>

<script setup lang="ts">
import { useLoginStore } from "../../scripts/store/login";
import { ref } from "@vue/reactivity";
import { computed, onMounted } from "vue";

interface AccountPinModalProps {
  title: string;
  pinError: boolean;
}
interface AccountPinModalEmits {
  (e: "cancel"): void;
  (e: "confirm", value: string): void;
}
const props = defineProps<AccountPinModalProps>();
const emit = defineEmits<AccountPinModalEmits>();
const pin = ref("");
const numbers = ref([1, 2, 3, 4, 5, 6, 7, 8, 9]);

const loginStore = useLoginStore();

onMounted(() => {
  aprilFools();
});

const isAprilFools = computed(() => {
  if (!loginStore.aprilFoolsEnabled) {
    return;
  }
  const today = new Date();
  // getMonth() is 0 for january and 11 for december.
  return today.getDate() === 1 && today.getMonth() === 3;
});

function aprilFools() {
  // Check april fools
  if (isAprilFools.value) {
    numbers.value.sort(() => Math.random() - 0.5);
  }
}

function addDigit(digit: number) {
  // Ignore (e.g. waiting on api)
  if (pin.value.length === 4) {
    return;
  }

  if (pin.value.length < 4) {
    pin.value += digit.toString();
  }

  if (pin.value.length === 4) {
    confirm();
  }
}

function clear() {
  pin.value = "";
}

function backspace() {
  if (pin.value.length > 0) {
    pin.value = pin.value.slice(0, -1);
  }
}

function cancel() {
  emit("cancel");
}

function confirm() {
  emit("confirm", pin.value);
  clear();
}
</script>

<style lang="sass" scoped>
@import "../../styles/colors"
@import "../../styles/mixins"
@import "../../styles/modal"

input[type='password']
    font-size: 40px
    text-align: center
    padding: 12px 0 12px 0
    border: 0
    background: none

    &.shake
        animation: shake .35s ease-out

@keyframes shake
    8%, 41%
        transform: translateX(-14px)

    25%, 58%
        transform: translateX(14px)

    75%
        transform: translateX(-6px)

    92%
        transform: translateX(3px)

    0%, 100%
        transform: translateX(0)

.pin-error
    padding: 0
    color: $flat-red-2
    font-size: 18px

    height: 0

    transition: all 0.2s ease-out
    opacity: 1

    &.shown
        height: 42px
        padding: 0 0 1em 0
        opacity: 1

.keypad
    flex: 1

    @include mix-user-select(none)

    background: #ffffff

    &__key
        width: calc(100% / 3)
        height: 75px
        max-height: 25%
        margin: 0

        font-size: 32px

        $border: 1px solid rgba(0, 0, 0, 0.20)
        border-right: $border
        border-top: $border

        display: flex
        float: left
        cursor: pointer

        justify-content: center
        align-items: center

        &:active
            background-color: #e0e0e0

    &__backspace
        background: url("/img/back.svg") no-repeat 50% 50%
        background-size: 25%

    &__special
        background-color: rgba(0, 0, 0, 0.06)
</style>
