import type { ColProps } from "ant-design-vue/es/grid/Col";
import { reactive, ref } from "@vue/reactivity";
import { defineStore } from "pinia";
import { generateUuid } from "../util";
import { isBicValid, isIbanValid } from "../../scripts/iban";
import { computed, Ref } from "vue";
import { useForm as useAntForm } from "ant-design-vue/es/form";
import type {
  Props,
  validateInfos as ValidateInfos,
} from "ant-design-vue/es/form/useForm";
import { Callbacks } from "ant-design-vue/es/form/interface";

/**
 * Ant sizes for forms.
 * Assumes the form takes the full width of the parent.
 */
export function useFormSizes({ label = true } = {}) {
  const labelCol: ColProps = { xs: { span: 24 }, md: { span: 4 } };
  let wrapperCol: ColProps = { xs: { span: 24 }, md: { span: 14 } };
  if (!label) {
    wrapperCol = {
      xs: { span: 24, offset: 0 },
      md: { span: 14, offset: 4 },
    };
  }
  return {
    labelCol,
    wrapperCol,
  };
}

export interface AntValidatorRule {
  // Not exported from Ant
  field: string;
  fullField: string;
  required: boolean;
  type: string;
}

export interface AntDebounceSettings {
  // Not exported from Ant
  leading?: boolean;
  wait?: number;
  trailing?: boolean;
}

export function pinValidator(rule: AntValidatorRule, val?: unknown) {
  if (!rule.required && !val) {
    return Promise.resolve();
  }

  if (!val || typeof val !== "string") {
    return Promise.reject("Please enter a pin code.");
  }

  if (val.length !== 4) {
    return Promise.reject("Pin code does not contain precisely four digits.");
  }
  for (var i = 0; i < val.length; i++) {
    if (!/^\d+$/.test(val)) {
      return Promise.reject("Pin code cannot contain letters.");
    }
  }
  return Promise.resolve();
}

export function ibanValidator(rule: AntValidatorRule, val: string) {
  if (!rule.required && !val) {
    return Promise.resolve();
  }

  if (!val) {
    return Promise.reject("Please enter an IBAN.");
  }

  if (!isIbanValid(val)) {
    return Promise.reject("Invalid IBAN.");
  }

  if (val !== val.toUpperCase()) {
    return Promise.reject("IBAN must be uppercase.");
  }

  return Promise.resolve();
}

export function bicValidator(rule: AntValidatorRule, val: string) {
  if (!rule.required && !val) {
    return Promise.resolve();
  }

  if (!val) {
    return Promise.reject("Please enter a BIC.");
  }

  if (!isBicValid(val)) {
    return Promise.reject("Invalid BIC.");
  }

  return Promise.resolve();
}

export function nonZeroAmountValidator(rule: AntValidatorRule, val?: unknown) {
  if (!rule.required && typeof val === "undefined") {
    return Promise.resolve();
  }

  if (typeof val !== "number") {
    return Promise.reject("Please enter a valid number.");
  }

  if (val === 0) {
    return Promise.reject("Please enter a non-zero amount.");
  }

  return Promise.resolve();
}

export function resetValidateInfos(infos: ValidateInfos) {
  for (const info of Object.values(infos)) {
    delete info.help;
    delete info.validateStatus;
  }
}

export function hasValidateInfoHints(infos: ValidateInfos) {
  for (const info of Object.values(infos)) {
    if (Array.isArray(info.help) && info.help.length) {
      return true;
    }
  }
  return false;
}

export const useVirtualKeyboardStore = defineStore(
  "virtualKeyboardStore",
  () => {
    const shown = ref(false);
    const inputs = reactive<Record<string, string>>({});
    const inputName = ref("");
    const enabled = ref(true);

    return {
      shown,
      inputs,
      inputName,
      enabled,
    };
  }
);

/**
 * Helper composable that:
 * - Generates a new unique id for an input, such that the simple-keyboard
 *   internal state can be updated when manual typing happens.
 * - Contains logic to show the virtual keyboard only on ipad.
 * @param initialValue
 */
export function useVirtualKeyboard(initialValue: string) {
  const id = generateUuid();
  const virtualKeyboardStore = useVirtualKeyboardStore();
  virtualKeyboardStore.inputs[id] = initialValue;
  const value = computed(() => virtualKeyboardStore.inputs[id]);

  function onInput(event: InputEvent) {
    if (event.target && event.target instanceof HTMLInputElement)
      virtualKeyboardStore.inputs[id] = event.target.value;
  }

  function onFocus() {
    virtualKeyboardStore.$patch({
      inputName: id,
      // When debugging in dev mode, set this to true.
      // navigator.platform is deprecated, we assume all touch based need
      // the virtual keyboard in order to work.
      shown: navigator.maxTouchPoints > 0 && virtualKeyboardStore.enabled,
    });
  }

  function clear() {
    virtualKeyboardStore.inputs[id] = "";
  }

  return {
    id,
    value,
    onFocus,
    onInput,
    clear,
  };
}
