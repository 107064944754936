<template lang="pug">
.modal(@click="$emit('cancel')")
  .inner.reason-inner(@click.stop)
    .title
      .left-close(type="button", value="Cancel", @click="$emit('cancel')")
      .center Other payment reason
      .right

    .reasons
      .reasons-grid
        .reason(
          v-for="reason in purchaseStore.reasons",
          @click="$emit('confirm', reason.name)"
        )
          .name {{ reason.name }}
</template>

<script setup lang="ts">
import { usePurchaseStore } from "../../scripts/store/purchase";
const purchaseStore = usePurchaseStore();
</script>

<style lang="sass" scoped>
@import "../../styles/colors"
@import "../../styles/mixins"
@import "../../styles/modal"

// TODO Can we merge this CSS with that of the account modal?
.modal
  .inner
    @include screen-small
      width: 100%
      height: 100%
      max-height: 100%

.title
  display: flex
  padding: $modal-title-padding-compact
  align-items: center

  .left-close
    flex: 2
    cursor: pointer
    height: 40px

    background-repeat: no-repeat
    background-image: url('/img/close.svg')
    background-position: 6px center
    background-size: $modal-top-button-size $modal-top-button-size
    opacity: .54

    border: 0
    border-radius: 0

    text-align: left
    font-size: $modal-top-button-size

    &:active
      background-color: #e0e0e0

    @include screen-compact
      background-size: $modal-top-button-size-compact $modal-top-button-size-compact

    &:last-child
      border-right: 0

  .center
    flex: 3

  .right
    flex: 2

.reason-inner
  width: 30vw
  max-height: 50vh
  max-width: 1000px

  @include screen-compact
    width: 95vw

  @include screen-small
    width: 100vw
    height: 100vh

.reasons
  @include mix-user-select(none)

  z-index: 1
  flex: 1
  -webkit-overflow-scrolling: touch
  overflow: auto

  background-color: $background-secondary
  border-bottom-left-radius: $border-radius-medium
  border-bottom-right-radius: $border-radius-medium

  @include screen-small
    border-radius: 0

.reasons-grid
  width: 100%
  padding: 8px

  display: grid

  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr))
  grid-gap: 10px

  // Safari workaround where items disappear
  transform: translate3d(0, 0, 0)
  -webkit-transform: translate3d(0, 0, 0)

.reason
  @include mix-user-select(none)

  position: relative
  height: 60px

  background-color: #ffffff
  border: 1px solid #dddddd
  border-radius: $border-radius-medium

  font-size: 15px

  overflow: hidden

  display: flex
  flex-flow: column
  justify-content: center
  align-items: center

  cursor: pointer

  &:active
    background-color: #e0e0e0

  > div
    display: flex
    justify-content: center
    align-items: center

  .name
    flex: 1
    padding: 4px
    word-break: break-all
</style>
