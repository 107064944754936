import { useModalStore } from "./store/modal";
import { ApiResponse } from "./api/api";
import { computed, ref } from "vue";
import { useLoginStore } from "./store/login";

// Converts a price integer (cents) to a properly formatted string
// Example: 420 -> € 4,20
export function priceStr(price: number, symbol: boolean = true): string {
  if (isNaN(price) || price === null) {
    return "";
  }

  if (symbol) {
    return new Intl.NumberFormat(navigator.language, {
      style: "currency",
      currency: "EUR",
    }).format(price / 100);
  } else {
    return new Intl.NumberFormat(navigator.language, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price / 100);
  }
}

export function getBalanceColour(balance?: number) {
  if (!balance) {
    return "black";
  }

  if (balance > 0) {
    return "#3f8600";
  } else if (balance < 0) {
    return "#cf1322";
  }

  return "black";
}

// Converts a price string to an integer (cents)
// Example: € 4,20 -> 420
export function strPrice(str: string): number {
  const priceFloat = parseFloat(str.replace("€", "").replace(",", "."));
  return Math.floor(priceFloat * 100);
}

// From: https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
export function generateUuid() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
    (
      parseInt(c) ^
      (crypto.getRandomValues(new Uint8Array(1))!![0] &
        (15 >> (parseInt(c) / 4)))
    ).toString(16)
  );
}

export function queryString(obj: {}): string {
  const s = Object.keys(obj)
    .map((key) => {
      if (obj[key] === null || obj[key] === "") {
        return null;
      }

      return key + "=" + obj[key];
    })
    .filter((s) => s)
    .join("&");

  return s ? "?" + s : "";
}

export function localeDate(
  dateOrDateString: string | Date,
  options?: Intl.DateTimeFormatOptions
): string {
  const date =
    typeof dateOrDateString === "string"
      ? new Date(dateOrDateString)
      : dateOrDateString;
  return date.toLocaleDateString("nl-NL", {
    year: "numeric",
    month: "long",
    day: "numeric",
    ...options,
  });
}

export function useLocaleDate(dateString: string) {
  return {
    date: computed(() => localeDate(dateString)),
  };
}

export function createDownload(
  filename: string,
  content: string,
  type: string = "text/plain;charset=utf-8"
) {
  const url = window.URL.createObjectURL(
    new Blob([content], {
      type: type,
    })
  );

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * @deprecated Do not use this, but instead use class Api.
 */
export function uploadFile(
  url: string,
  formData: FormData,
  parse: boolean = true
): any {
  const token = localStorage.getItem("access_token");
  const request = new XMLHttpRequest();
  request.open("POST", url, true);
  request.setRequestHeader("Accepts", "application/json");

  if (token !== null) {
    request.setRequestHeader("Authorization", `Bearer ${token}`);
  }

  const promise = new Promise((resolve, reject) => {
    request.onload = function () {
      if (this.status >= 200 && this.status < 400) {
        resolve(parse ? JSON.parse(this.response) : this.response);
      } else {
        reject({
          status: this.status,
          response: parse ? JSON.parse(this.response) : this.response,
        });
      }
    };

    request.onerror = () => reject(null);
  });

  request.send(formData);
  return promise;
}

/**
 * @deprecated All APIs should start making use of class Api.
 */
export function jsonRequest(
  method: string,
  url: string,
  data?: any,
  parse: boolean = true
): Promise<any> {
  const token = localStorage.getItem("access_token");
  const request = new XMLHttpRequest();
  request.open(method, url, true);
  request.setRequestHeader("Accepts", "application/json");

  if (token !== null) {
    request.setRequestHeader("Authorization", `Bearer ${token}`);
  }

  if (data !== undefined) {
    request.setRequestHeader("Content-Type", "application/json");
    data = JSON.stringify(data);
  }

  const promise = new Promise((resolve, reject) => {
    request.onload = function () {
      if (this.status >= 200 && this.status < 400) {
        if (this.status == 204) {
          parse = false;
        }
        resolve(parse ? JSON.parse(this.response) : this.response);
      } else {
        reject({
          status: this.status,
          response: parse ? JSON.parse(this.response) : this.response,
        });
      }
    };

    request.onerror = () => reject(null);
  });

  request.send(data);
  return promise;
}

// Polyfill for Promise.finally
// https://stackoverflow.com/questions/38830314/promises-execute-something-regardless-of-resolve-reject
let worker = (p, f, done) => {
  return p.constructor.resolve(f()).then(done, done);
};
Object.defineProperty(Promise.prototype, "finally", {
  value(f) {
    return this.then(
      (result) => worker(this, f, () => result),
      (error) =>
        worker(this, f, () => {
          throw error;
        })
    );
  },
});

/**
 * Helper to make validateFields callback a promise
 * @param form an ant-design form
 */
export function validateAntForm(form: any) {
  return new Promise((resolve, reject) => {
    form.validateFields((err, values) => {
      if (err) {
        return reject(err);
      }

      resolve(values);
    });
  });
}

export const useAdminRequests = () => {
  const callsInFlight = ref(0);
  const requestsActive = computed(() => callsInFlight.value > 0);

  const request = async (
    method: string,
    url: string,
    data?: any,
    parse: boolean = true
  ) => {
    callsInFlight.value++;

    const promise = jsonRequest(method, url, data, parse);

    promise.finally(() => callsInFlight.value--);

    return promise;
  };
  const requestWith = async <T>(promise: Promise<T>): Promise<T> => {
    callsInFlight.value++;

    promise.finally(() => callsInFlight.value--);

    return promise;
  };

  return { requestsActive, request, requestWith };
};

/**
 * @deprecated Handle messages where they are created, for example:
 * notification.success({
 *   message: `Foo`,
 *   description: 'Bar'
 * });
 * If a modal style is desired (for purchase), use the modalStore directly.
 */
export function showMessage(message: string, title: string = "") {
  const modalStore = useModalStore();
  modalStore.showMessage(message, title);
}

export function debounce(fn, delay) {
  // FIXME: Figure out why it is erroring when timeout is simply a number.
  let timeout: ReturnType<typeof setTimeout>;
  return function (...args) {
    if (timeout !== undefined) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => fn.apply(this, args), delay);
  };
}

/**
 * @deprecated Handle error where they are caught, for example, when using Api:
 * notification.error({
 *   message: `Failed to update product.`,
 *   description: !(e instanceof PydanticError) ? "Unknown error." : e.toDiv(),
 * });
 * or show the text in an appropriate place, such as on the button that executed the
 * api request.
 */
export function showError(error: any, title: string = "") {
  let errorString = "";
  if (error instanceof ApiResponse) {
    // An ApiResponse was given, try to parse it into something sensible.
    if (error.errorCode != 0) {
      errorString = `API error (${error.errorCode}/${error.errorIdentifier}): ${error.errorMessage}`;
    } else {
      errorString = `API error (${error.errorIdentifier}): ${error.errorMessage}`;
    }
  } else if (
    error instanceof Object &&
    "status" in error &&
    "response" in error
  ) {
    // Raw json response was given
    const errorMessage = error.response
      ? error.response.error
      : error.errorMessage;
    errorString = `Unknown error (${error.status}: ${errorMessage})`;
  } else {
    errorString = error;
  }
  const modalStore = useModalStore();
  let modal = modalStore.showError(errorString, title);

  if (error.errorIdentifier == "invalid_access_token") {
    const loginStore = useLoginStore();
    modal.cancelCallback = loginStore.logout;
    modal.confirmCallback = loginStore.logout;
  }
}
