<template lang="pug">
div
  //- Stack of modal dialogs.
  transition(name="modal-stack-animation")
    .modal-stack(v-show="store.modalStack.length > 0")
      transition-group.modal-animation-container(
        name="modal-animation",
        tag="div"
      )
        //- Loop through the stack:
        .modal-container(
          v-for="(item, index) in store.modalStack",
          :style="{ 'z-index': 100 + index }",
          :key="item.id"
        )
          .modal--loading(
            v-if="item.modalView === 'loading'",
            style="display: flex; justify-content: center;align-items: center; height: 100%"
          )
            a-spin(size="large")

          //- The different types of modals:
          //- Cash
          price-modal.modal--cash(
            v-if="item.modalView === 'cash'",
            title="Contant",
            :price="priceStr(item.totalPrice)",
            @cancel="cancelModal",
            @confirm="confirmModal"
          )
            | Put the appropriate cash amount in the cash box

          //- PIN/card
          card-transaction-modal.modal--card(
            v-if="item.modalView === 'card' && item.terminal",
            :terminal="item.terminal",
            :transactionId="item.pendingTransactionID",
            :price="priceStr(item.totalPrice)",
            @cancel="cancelModal",
            @confirm="confirmModal",
            @retry="retryModal"
          )

          price-modal.modal--cash(
            v-if="item.modalView === 'card' && !item.terminal",
            title="PIN",
            :price="priceStr(item.totalPrice)",
            @cancel="cancelModal",
            @confirm="confirmModal"
          )
            | Enter the following amount on the payment terminal

          //- Other
          other-reason-modal.modal--other(
            v-if="item.modalView === 'otherReason'",
            @cancel="cancelModal",
            @confirm="confirmModal"
          )

          price-modal.modal--other(
            v-if="item.modalView === 'other'",
            title="Anders voldaan",
            :price="priceStr(item.totalPrice)",
            @cancel="cancelModal",
            @confirm="confirmModal"
          )
            | {{ item.checkoutReason }}

          //- By account
          account-modal(
            v-if="item.modalView === 'account'",
            :price="item.totalPrice",
            :title="item.title",
            :accounts="item.accounts",
            @cancel="cancelModal",
            @confirm="confirmModal",
            @refresh="item.refresh()"
          )

          //- Pin modal for account
          account-pin-modal(
            v-if="item.modalView === 'pin'",
            :title="item.title",
            :pinError="item.pinError",
            @cancel="cancelModal",
            @confirm="confirmModal"
          )

          //- Topup account
          topup-modal(
            v-if="item.modalView === 'topup'",
            @cancel="cancelModal",
            @confirm="confirmModal",
            :original="item.original"
          )

  // Checkmark animation
  transition(name="checkmark-animation")
    .checkmark(v-if="store.showConfirmPurchaseCheckmark")
      checkmark
      .bottom-text {{ store.confirmPurchaseCheckmarkText }}
</template>

<script setup lang="ts">
import AccountModal from "./account_modal.vue";
import PriceModal from "./price_modal.vue";
import TopupModal from "./topup_modal.vue";
import AccountPinModal from "./account_pin_modal.vue";
import CardTransactionModal from "../card_transactions/CardTransactionModal.vue";
import Checkmark from "./checkmark.vue";
import { usePurchaseStore } from "../../scripts/store/purchase";
import OtherReasonModal from "./other_reason_modal.vue";
import { priceStr } from "../../scripts/util";

const store = usePurchaseStore();

function confirmModal() {
  store.modalConfirmed(
    store.modalStack[store.modalStack.length - 1],
    arguments
  );
}

function cancelModal() {
  store.modalCancelled(
    store.modalStack[store.modalStack.length - 1],
    arguments
  );
}

function retryModal() {
  store.modalRetry(store.modalStack[store.modalStack.length - 1]);
}
</script>

<style lang="sass">
@import "../../styles/common"
@import "../../styles/modal"

.checkmark
    position: fixed
    top: calc(50% - 90px)
    left: calc(50% - 90px)
    z-index: 1000

    .bottom-text
        font-size: 0.95em
        text-align: center

        color: #ffffff

        position: absolute
        left: -4px
        right: 0
        bottom: 26px

.checkmark-animation-enter-active,
.checkmark-animation-leave-active
    transition: all 0.3s ease-out

.checkmark-animation-enter-from
    opacity: 0
    clip-path: circle(25%)
    -webkit-clip-path: circle(25%)

.checkmark-animation-enter-to
    opacity: 1
    clip-path: circle(100%)
    -webkit-clip-path: circle(100%)

.checkmark-animation-leave-to
    opacity: 0
</style>
