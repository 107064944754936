<template lang="pug">
.modal(@click="$emit('cancel')")
  .inner(@click.stop)
    .title {{ props.title }}
    .text
      slot

    p.price
      | Amount:
      br
      span.total {{ props.price }}

    .buttons
      input(@click="emit('cancel')", type="button", value="Annuleer")
      input.confirm(
        @click="emit('confirm')",
        type="button",
        v-model="confirmText"
      )
</template>

<script setup lang="ts">
import { computed, onUnmounted } from "vue";
import { useTimer } from "../../scripts/composables/modal";

interface PriceModalProps {
  price: string;
  title: string;
}
interface PriceModalEmits {
  (e: "confirm"): void;
  (e: "cancel"): void;
}
const props = defineProps<PriceModalProps>();
const emit = defineEmits<PriceModalEmits>();
const { secondsLeft, stop } = useTimer(() => emit("confirm"));
const confirmText = computed(() => "Gereed (" + secondsLeft.value + ")");
onUnmounted(stop);
</script>

<style lang="sass" scoped>
@import "../../styles/vars"
@import "../../styles/modal"

.modal
    .inner
        width: 400px
        height: 600px
        min-height: 400px
        max-height: 90vh

        @include screen-small
            width: 100%
            height: 100%
            max-height: 100%

    .text
        font-size: 1.2em
        margin: 20px 24px 0 24px

    .price
        font-family: '-apple-system-caption2', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif
        font-weight: 300
        font-size: 2em

        display: flex
        flex-flow: column
        justify-content: center
        align-items: center

        flex: 1

        .total
            color: #004b99
</style>
