const ANIMATION_DURATION = 300;
import { defineStore } from "pinia";

interface SnackbarStoreState {
  show: boolean;
  text: string;
  actionText: string;
  action: Function | null;
  durationId?: ReturnType<typeof setTimeout>;
  animationId?: ReturnType<typeof setTimeout>;
}

export const useSnackbarStore = defineStore("snackbarStore", {
  state: (): SnackbarStoreState => ({
    show: false,
    text: "",
    actionText: "",
    action: null,
    durationId: undefined,
    animationId: undefined,
  }),
  actions: {
    clear(immediate = false) {
      clearTimeout(this.durationId);
      clearTimeout(this.animationId);

      this.show = false;
      this.action = null;

      if (immediate) {
        this.text = "";
        this.actionText = "";
      } else {
        this.animationId = setTimeout(() => {
          this.text = "";
          this.actionText = "";
        }, ANIMATION_DURATION);
      }
    },
    doShow(
      text: string,
      actionText: string,
      ms: number | null,
      action: Function | null
    ) {
      this.clear(true);

      this.show = true;
      this.text = text;
      this.actionText = actionText;
      this.action = action;

      if (ms) {
        this.durationId = setTimeout(() => {
          this.clear();
        }, ms);
      }
    },
    doAction() {
      if (this.action) {
        this.action();
      }
    },
  },
});
