import { defineStore } from "pinia";

class ModalContext {
  id: number = -1;

  confirmCallback: (any) => void;
  cancelCallback: (any) => void;

  get modalView() {
    return "unknown";
  }

  onConfirm(args) {
    if (this.confirmCallback) {
      this.confirmCallback(args);
    }
  }

  onCancel(args) {
    if (this.cancelCallback) {
      this.cancelCallback(args);
    }
  }
}

class MessageModalContext extends ModalContext {
  title: string;
  message: string;

  constructor(message: string, title: string) {
    if (!title) {
      title = "Message";
    }
    super();
    this.title = title;
    this.message = message;
  }

  get modalView() {
    return "message";
  }
}

class ErrorModalContext extends MessageModalContext {
  constructor(message: string, title: string) {
    if (!title) {
      title = "Error";
    }
    super(message, title);
  }

  get modalView() {
    return "error";
  }
}

class PromptModalContext extends ModalContext {
  title: string;
  message: string;

  constructor(message: string, title: string) {
    if (!title) {
      title = "Prompt";
    }

    super();
    this.message = message;
    this.title = title;
  }

  get modalView() {
    return "prompt";
  }
}

class ConditionsModelContext extends ModalContext {
  constructor(confirmCallback, cancelCallback) {
    super();
    this.confirmCallback = confirmCallback;
    this.cancelCallback = cancelCallback;
  }

  get modalView() {
    return "condition";
  }
}

interface ModalStoreState {
  modalStack: ModalContext[];
  modalStackId: number;
}
export const useModalStore = defineStore("modalStore", {
  state: (): ModalStoreState => ({
    modalStack: [],
    modalStackId: 0,
  }),
  actions: {
    modalConfirmed(modal: ModalContext, confirmArguments) {
      modal.onConfirm(confirmArguments);
      this.modalStack.splice(this.modalStack.indexOf(modal), 1);
    },
    modalCancelled(modal: ModalContext, cancelArguments) {
      modal.onCancel(cancelArguments);
      this.modalStack.splice(this.modalStack.indexOf(modal), 1);
    },
    showMessage(message: string, title: string = ""): ModalContext {
      const modal = new MessageModalContext(message, title);
      this.pushModal(modal);
      return modal;
    },
    showError(message: string, title: string = ""): ModalContext {
      const modal = new ErrorModalContext(message, title);
      this.pushModal(modal);
      return modal;
    },
    showPrompt(message: string, callback: (arg) => void, title: string = "") {
      const modal = new PromptModalContext(message, title);

      modal.confirmCallback = (args) => {
        const result: string = args[0];
        callback(result);
      };

      this.pushModal(modal);
    },
    showConditions(callbackConfirm: () => void, callbackCancel: () => void) {
      const modal = new ConditionsModelContext(callbackConfirm, callbackCancel);
      this.pushModal(modal);
    },
    pushModal(modal: ModalContext) {
      modal.id = this.modalStackId++;
      this.modalStack.push(modal);
    },
    popModal() {
      this.modalStack.pop();
    },
  },
});
