import { loginApi } from "../api/login";
import { userConditionApi } from "../api/user_condtion";
import { Config, User, UserRegister } from "../types";
import { jsonRequest } from "../util";
import { useModalStore } from "./modal";
import * as Sentry from "@sentry/vue";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { Router } from "vue-router";

export const useLoginStore = defineStore("loginStore", () => {
  const user = ref<User | null>(null);
  const register = ref<UserRegister | null>(null);
  const aprilFoolsEnabled = ref(false);
  const posWrappedAvailable = ref(false);
  const requireTfa = ref(false);
  const requireSettingAccountPin = ref(false);

  function initialize(router: Router): Promise<void> | null {
    if (!onNonLoginPage.value) {
      const accessToken = localStorage.getItem("access_token");
      if (accessToken === null) {
        const next = encodeURIComponent(
          `${location.pathname}${location.search}`
        );
        location.href = `/login?next=${next}`;
        return null;
      }
    }

    return jsonRequest("GET", "/api/config/").then(
      (config) => {
        handleConfig(config, router);
      },
      (error) => {
        alert("Error loading " + JSON.stringify(error));
      }
    );
  }

  async function loginWith(username: string, password: string) {
    const response = await loginApi.login(username, password);
    localStorage.setItem("access_token", response.result.access_token);
  }

  async function oauthWith(code: string) {
    const response = await loginApi.oauth(code);
    localStorage.setItem("access_token", response.result.access_token);
  }

  function logout() {
    localStorage.removeItem("access_token");
    location.pathname = "/login";
  }

  function handleConfig(config: Config, router: Router) {
    aprilFoolsEnabled.value = config.aprilFoolsEnabled;
    posWrappedAvailable.value = config.posWrappedAvailable;
    requireTfa.value = config.requireTfa;
    requireSettingAccountPin.value = config.requireSettingAccountPin;

    localStorage.setItem("config", JSON.stringify(config));

    user.value = config.user;
    register.value = config.register;

    if (user.value) {
      Sentry.setUser({
        id: user.value.id.toString(),
        username: user.value.username,
      });

      localStorage.setItem("username", user.value.username);

      // Redirect logic needed on startup:
      if (
        !user.value.accountId &&
        hasCapability("PAYMENT_ACCOUNT_SELF") &&
        !onStaticPages.value
      ) {
        // No account linked, is a normal user with an account, redirect to setup (request an account page)
        router.push("/setup");
      } else if (
        location.pathname.indexOf("/pos") !== -1 &&
        window.location.search.length > 0
      ) {
        // Clear login token from url query params.
        router.push("/pos");
      }

      if (!config.acceptedCurrentCondition) {
        const modalStore = useModalStore();
        modalStore.showConditions(() => acceptConditions(), logout);
      }
    } else {
      // Redirect to login screen if no user is authed and we're not on a login page.
      if (!onNonLoginPage.value) {
        const next = encodeURIComponent(
          `${location.pathname}${location.search}`
        );
        location.href = `/login?next=${next}`;
      }
    }
  }

  function acceptConditions() {
    userConditionApi.updateAcceptedCondition().catch(() => {
      logout();
      alert("Failed to update Accepted Condition");
    });
  }

  const onNonLoginPage = computed(() => {
    const non_login_paths = ["/login", "/slides", "/about"];

    for (const path of non_login_paths) {
      if (location.pathname.startsWith(path)) {
        return true;
      }
    }
    return false;
  });
  const onStaticPages = computed(() => location.pathname.startsWith("/about"));
  const showAccountRequest = computed(
    () =>
      user.value && user.value.accountId === null && user.value.viaductUserId
  );

  function hasCapability(cap: string) {
    return hasCapabilities([cap]);
  }

  function hasCapabilities(caps: string[]) {
    if (register.value === null) {
      return false;
    }
    for (let i = 0; i < caps.length; i++) {
      if (register.value.capabilities.indexOf(caps[i]) < 0) {
        return false;
      }
    }
    return true;
  }

  return {
    aprilFoolsEnabled,
    posWrappedAvailable,
    requireSettingAccountPin,
    requireTfa,
    user,
    showAccountRequest,
    oauthWith,
    hasCapability,
    initialize,
    loginWith,
    logout,
  };
});
