<template lang="pug">
.modal(@click="$emit('cancel')")
  .inner(@click.stop)
    .title
      span(v-if="props.terminal.brand === 'sepay'")
        | The following amount is ready on the blue payment terminal:
      span(v-else)
        | The following amount is ready on the payment terminal:

    .centered
      p.icons
        div(:class="classMapping")

      p
        b
          | Status:
        br
        span {{ statusText }}

    p.price
      div(v-if="!showRetry")
        | Bedrag:
        br
        span.total {{ props.price }}

    .buttons
      input.confirm(
        v-if="showRetry",
        @click="emit('retry')",
        type="button",
        v-model="again"
      )
</template>

<script setup lang="ts">
import { computed, watch } from "vue";
import { usePendingTransaction } from "./CardTransactionBase";
import type {
  CardTransactionModalEmits,
  CardTransactionModalProps,
} from "./CardTransactionBase";
import { TransactionStatus } from "../../scripts/types";
import { ref } from "@vue/reactivity";

const props = defineProps<CardTransactionModalProps>();
const emit = defineEmits<CardTransactionModalEmits>();

const state = usePendingTransaction(ref(props.transactionId));

watch(state, () => {
  if (
    state.value.state === "loaded" &&
    state.value.transactionStatus === TransactionStatus.COMPLETED
  ) {
    emit("confirm", true);
  }
});

const showRetry = computed(
  () =>
    state.value.state === "loaded" &&
    state.value.transactionStatus !== TransactionStatus.PENDING &&
    state.value.transactionStatus !== TransactionStatus.COMPLETED
);
const again = computed(() => "Try again");

const classMapping = computed(() => {
  return {
    awaiting_payment:
      state.value.state === "loading" ||
      (state.value.state === "loaded" &&
        state.value.transactionStatus === TransactionStatus.PENDING),
    cancelled:
      state.value.state === "error" ||
      (state.value.state === "loaded" && state.value.failed),
  };
});

const statusText = computed(() => {
  switch (state.value.state) {
    case "loading":
      return "Waiting for payment...";
    case "error":
      return `POS failed ${state.value.error}`;
    case "loaded":
      switch (state.value.transactionStatus) {
        case TransactionStatus.DELETED:
          return "Payment cancelled";
        case TransactionStatus.CANCELLED:
          return "Payment cancelled";
        case TransactionStatus.EXPIRED:
          return "Payment expired";
        case TransactionStatus.PENDING:
          return "Waiting for payment...";
        case TransactionStatus.COMPLETED:
          return "Payment successful!";
      }
  }
});
</script>

<style lang="sass" scoped>
@import "../../styles/_vars.sass"
@import "../../styles/modal.sass"

.modal
  .inner
    width: 400px
    height: 600px
    min-height: 400px
    max-height: 90vh

    .centered
      height: 100%
      display: flex

      flex-flow: column
      justify-content: center

      font-size: 1.5em


    @include screen-small
      width: 100%
      height: 100%
      max-height: 100%

    .icons

      & > div
        width: 100%
        height: 150px
        background-size: contain
        background-repeat: no-repeat
        background-position: center

      .awaiting_payment
        background-image: url("/img/contactless.svg")

      .cancelled
        background-image: url("/img/alert-circle.svg")

  .text
    font-size: 1.2em
    margin: 20px 24px 0 24px

  .price
    font-family: '-apple-system-caption2', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif
    font-weight: 300
    font-size: 2em

    display: flex
    flex-flow: column
    justify-content: center
    align-items: center

    flex: 1

    .total
      color: #004b99

  .buttons
    & > input
      width: 100%
</style>
