<template lang="pug">
.modal(@click="close")
  .inner.account-inner(@click.stop)
    .title
      .left-close(type="button", value="Cancel", @click="close()")
      .center {{ title }}
      .right

    form.search-container(@submit.prevent="")
      img.refresh-icon(src="/img/refresh.svg", @click="emit('refresh')")
      .search
        .search__icon
        input(
          :id="id",
          type="search",
          placeholder="Search",
          :value="value",
          ref="searchQueryRef",
          @input="onInput",
          @focus="onFocus",
          @keyup.enter="selectFirstAccount"
        )
      .search__clear(v-on:click="clear")
        | Cancel

    .accounts
      .quick-select
        .quick-select-item(
          v-for="l in 'ABCDEFGHIJKLMNOPQRSTUVWXYZ@'",
          @click="quickSelect(l)",
          :class="{ selected: quickSelectChars == l }"
        )
          | {{ l }}

      .accounts-grid
        .account(
          v-for="account in filteredAccounts",
          :class="accountClasses(account)",
          @click="$emit('confirm', account)"
        )
          .name {{ account.name }}

          .account-balance(v-if="barMode")
            | {{ priceStr(account.balance) }}

          .badge(v-if="barMode && account.directDebit") SEPA
          .badge(v-if="barMode && account.type == 'event'") EVENT

        .show-more(
          v-if="hideInactive",
          :class="showMoreClasses()",
          @click.prevent="hideInactive = false"
        )
          | Show more
</template>

<script setup lang="ts">
import Fuse, { FuseOptions } from "fuse.js";

import type { Account } from "../../scripts/types";
import { useLoginStore } from "../../scripts/store/login";
import { priceStr } from "../../scripts/util";
import { ref, computed, onMounted } from "vue";
import { useVirtualKeyboard } from "../../scripts/composables/form";

// Hide accounts under the "Show more" button if they aren't used for more than n days.
const HIDE_THRESHOLD_DAYS = 75;

interface AccountModalProps {
  price: number;
  accounts: Account[];
  title: string;
}

interface AccountModalEmits {
  (e: "cancel"): void;
  (e: "refresh"): void;
  (e: "confirm", account: Account): void;
}

const props = defineProps<AccountModalProps>();
const emit = defineEmits<AccountModalEmits>();

const selectedAccount = ref<Account | null>(null);
const hideInactive = ref(true);
const quickSelectChars = ref("");

const loginStore = useLoginStore();
const searchQueryRef = ref(null);
const { id, value, onInput, onFocus, clear } = useVirtualKeyboard("");

onMounted(() => {
  if (searchQueryRef.value) {
    (searchQueryRef.value as HTMLInputElement).focus();
  }
});

function close(): void {
  selectedAccount.value = null;
  clear();
  emit("cancel");
}

function accountClasses(account: Account): object {
  return {
    "bar-mode": barMode.value,
    broke:
      barMode.value &&
      !account.directDebit &&
      account.type !== "event" &&
      account.balance < props.price,
    "long-name": account.name.length > 21,
  };
}

function showMoreClasses() {
  return {
    "bar-mode": barMode.value,
  };
}

const barMode = computed(() =>
  loginStore.hasCapability("PAYMENT_ACCOUNT_OTHER")
);

const filteredAccounts = computed(() => {
  let accounts: Account[] = [];
  if (hideInactive.value) {
    let now = Date.now();
    accounts = props.accounts
      .slice()
      .filter((a) => daysSince(now, a.lastUsed) < HIDE_THRESHOLD_DAYS);
  } else {
    accounts = props.accounts.slice();
  }

  if (quickSelectChars.value.length > 0) {
    accounts = accounts.filter(
      (a) =>
        a.name &&
        quickSelectChars.value.indexOf(
          a.name[0].normalize("NFC").toUpperCase()
        ) >= 0
    );
  }

  if (value.value.length == 0) {
    return accounts
      .filter((a) => a.type === "event")
      .concat(accounts.filter((a) => a.type !== "event"));
  }

  const fuse = new Fuse(accounts, {
    keys: ["name"],
    shouldSort: true,
    threshold: 0.4,
    includeScore: false,
    includeMatches: false,
  });
  return fuse.search(value.value);
});

function daysSince(now: number, value: string): number {
  return Math.round((now - new Date(value).getTime()) / (1000 * 60 * 60 * 24));
}

function selectFirstAccount() {
  if (filteredAccounts.value.length === 1) {
    emit("confirm", filteredAccounts.value[0] as Account);
  }
}

function quickSelect(chars: string) {
  // Allow de-selection
  if (quickSelectChars.value == chars) {
    quickSelectChars.value = "";
  } else {
    quickSelectChars.value = chars;
  }
}
</script>

<style lang="sass" scoped>
@import "../../styles/vars"
@import "../../styles/mixins"
@import "../../styles/colors"
@import "../../styles/modal"
@import "../../styles/search"

.modal
    .inner
        @include screen-small
            width: 100%
            height: 100%
            max-height: 100%

.title
    display: flex
    padding: $modal-title-padding-compact
    align-items: center

    .left-close
        flex: 2
        cursor: pointer
        height: 40px

        background-repeat: no-repeat
        background-image: url('/img/close.svg')
        background-position: 6px center
        background-size: $modal-top-button-size $modal-top-button-size
        opacity: .54

        border: 0
        border-radius: 0

        text-align: left
        font-size: $modal-top-button-size

        &:active
            background-color: #e0e0e0

        @include screen-compact
            background-size: $modal-top-button-size-compact $modal-top-button-size-compact

        &:last-child
            border-right: 0

    .center
        flex: 3

    .right
        flex: 2

.account-inner
    width: 80vw
    height: 95vh
    max-width: 1000px

    @include screen-compact
        width: 95vw

    @include screen-small
        width: 100vw
        height: 100vh

.accounts
    @include mix-user-select(none)

    z-index: 1
    flex: 1
    -webkit-overflow-scrolling: touch
    overflow: auto

    background-color: $background-secondary
    border-bottom-left-radius: $border-radius-medium
    border-bottom-right-radius: $border-radius-medium

    @include screen-small
        border-radius: 0

.quick-select
    display: flex
    justify-content: space-between
    margin: 8px 4px 0 4px

    @include screen-small
        display: none

    .quick-select-item
        @include mix-user-select(none)
        flex: 1
        height: 36px

        vertical-align: middle
        line-height: 36px
        margin: 0 1px 0 1px
        background-color: #ffffff
        border: 1px solid #dddddd
        border-radius: $border-radius-medium
        cursor: pointer
        font-size: 15px

        &:active, &.selected
            background-color: #e0e0e0

        @media (hover: hover) and (pointer: fine)
            &:hover
                background-color: #e0e0e0

.accounts-grid
    width: 100%
    padding: 8px

    display: grid

    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr))
    grid-gap: 8px

    // Safari workaround where items disappear
    transform: translate3d(0, 0, 0)
    -webkit-transform: translate3d(0, 0, 0)

.account
    @include mix-user-select(none)

    position: relative
    height: 60px

    background-color: #ffffff
    border: 1px solid #dddddd
    border-radius: $border-radius-medium

    font-size: 15px

    overflow: hidden

    display: flex
    flex-flow: column
    justify-content: center
    align-items: center

    cursor: pointer

    &:active
        background-color: #e0e0e0

    &.long-name
        .name
            font-size: 13px

    &.bar-mode
        height: 70px

    > div
        display: flex
        justify-content: center
        align-items: center

    .name
        flex: 1
        padding: 4px
        word-break: break-all

    &.broke .account-balance
        color: $flat-red-2

    .badge
        display: block

        padding: 0 4px 0 4px

        position: absolute
        right: 5px
        bottom: 4px

        color: #777777
        font-size: 8px
        line-height: 15px

        height: 15px

        border: 1px solid #dddddd
        border-radius: 2px

    .account-balance
        width: 100%
        border-top: 1px solid #dddddd
        height: 25px
        display: flex
        position: relative

.show-more
    position: relative
    height: 60px
    line-height: 60px

    background-color: #ffffff
    border: 1px solid #dddddd
    border-radius: $border-radius-medium

    font-size: 15px
    color: $flat-blue-1

    cursor: pointer

    &:active
        background-color: #e0e0e0

    &.bar-mode
        height: 70px
        line-height: 70px

.refresh-icon
    font-size: 20px
    line-height: 40px
    padding: 4px 12px 4px 4px
    cursor: pointer

    &:active
        background-color: #e0e0e0
</style>
