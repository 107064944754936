import { h, VNode } from "vue";
import { PaginatedResponse } from "./pagination";

export function isApiResponse(obj: unknown): obj is ApiResponse<unknown> {
  if (typeof obj !== "object" || !obj) {
    return false;
  }

  if (
    !("error" in obj) ||
    !("errorCode" in obj) ||
    !("errorIdentifier" in obj) ||
    !("errorMessage" in obj)
  ) {
    return false;
  }

  if (
    typeof obj["error"] !== "boolean" &&
    typeof obj["errorCode"] !== "number" &&
    typeof obj["errorIdentifier"] !== "string" &&
    typeof obj["errorMessage"] !== "string"
  ) {
    return false;
  }
  return true;
}

export function urlWithSearchParams(url: string, obj: {}): string {
  const queryString = objectToSearchParamString(obj);

  if (queryString) {
    url += "?" + queryString;
  }

  return url;
}

export function objectToSearchParamString(obj: {}): string | undefined {
  const record: Record<string, string> = {};

  for (const [key, value] of Object.entries(obj)) {
    record[key + ""] = value + ""; // Best effort
  }

  if (Object.entries(record).length) {
    return new URLSearchParams(record).toString();
  }
}

export class ApiResponse<T> {
  public error: boolean = false;
  public errorCode: number = 0;
  public errorIdentifier: string = "";
  public errorMessage: string | null = null;
  public pydanticError?: PydanticError;

  public result: T;

  static is(obj: unknown): obj is ApiResponse<unknown> {
    if (typeof obj !== "object" || !obj) {
      return false;
    }

    return obj instanceof ApiResponse;
  }

  static toErrorDescriptionDiv(obj: unknown) {
    if (!ApiResponse.is(obj) || !obj.error) {
      // not an error
      return;
    }

    if (!obj.pydanticError) {
      return "Unknown error.";
    }

    return obj.pydanticError.toDiv();
  }
}

export interface PydanticErrorDetail {
  loc: string[];
  msg: string;
  type: string;
  ctx?: Record<string, unknown>;
}

export interface PydanticErrorResponse {
  detail: PydanticErrorDetail[];
}

export class PydanticError {
  error: PydanticErrorResponse;

  constructor(err: PydanticErrorResponse) {
    this.error = err;
  }

  toHumanReadableStrings(): string[] {
    return this.error.detail.flatMap((d) => {
      const propertyName = d.loc[d.loc.length - 1];
      if (!propertyName) {
        return [];
      }

      if (d.type.startsWith("assertion_error")) {
        return d.msg;
      }

      if (d.type.startsWith("value_error.missing")) {
        // E.g. msg: "field required"
        return `Missing required property '${propertyName}'.`;
      }

      if (d.type.startsWith("value_error") && d.msg.startsWith("ensure")) {
        // E.g. msg: "ensure this value is greater than or equal to 0"
        return (
          "Please " + d.msg.replace("this value", `'${propertyName}'`) + "."
        );
      }

      if (d.type.startsWith("value_error") && d.msg.startsWith("Invalid")) {
        // E.g. Invalid expression '%3E%3D1' for NumberFilter.
        return d.msg;
      }

      if (d.type.startsWith("type_error")) {
        // E.g. msg: "str type expected"
        const groups = d.msg.match(/^(?<expected>.*)\stype/)?.groups;
        if (groups?.["expected"]) {
          return `Expected '${propertyName}' to be of type '${groups["expected"]}'.`;
        }
      }

      return `Property '${propertyName}' contains invalid data.`;
    });
  }

  toDiv(): VNode {
    const paragraphs = this.toHumanReadableStrings().map((s) => {
      return h("p", { style: { margin: 0 } }, s);
    });

    return h("div", paragraphs);
  }

  static isDetail(obj: unknown): obj is PydanticErrorDetail {
    if (typeof obj !== "object" || !obj) {
      return false;
    }

    if (!("loc" in obj) || !("msg" in obj) || !("type" in obj)) {
      return false;
    }

    if (!Array.isArray(obj["loc"])) {
      return false;
    }

    return true;
  }

  static is(obj: unknown): obj is PydanticErrorResponse {
    if (typeof obj !== "object" || !obj) {
      return false;
    }

    if (!("detail" in obj)) {
      return false;
    }

    if (!Array.isArray(obj["detail"])) {
      return false;
    }

    return obj["detail"].every((d) => PydanticError.isDetail(d));
  }
}

export interface PaginationOptions {
  page: number;
  perPage: number;
}

/**
 * @deprecated
 */
export async function unwrapPagination<T>(
  api: (
    options: PaginationOptions
  ) => Promise<ApiResponse<PaginatedResponse<T>>>
): Promise<T[]> {
  let items: T[] = [];
  let resp = (await api({ page: 1, perPage: 50 })).result;
  items.push(...resp.items);

  for (let i = 2; i <= resp.pageCount; i++) {
    let resp = (await api({ page: i, perPage: 50 })).result;
    items.push(...resp.items);
  }
  return items;
}

export class Api {
  get<T>(path: string): Promise<ApiResponse<T>> {
    return this.executeRequest("GET", path);
  }

  post<T>(path: string, data: any): Promise<ApiResponse<T>> {
    return this.executeRequest("POST", path, data);
  }

  patch<T>(path: string, data: any): Promise<ApiResponse<T>> {
    return this.executeRequest("PATCH", path, data);
  }

  put<T>(path: string, data: any): Promise<ApiResponse<T>> {
    return this.executeRequest("PUT", path, data);
  }

  delete<T>(path: string, data: any): Promise<ApiResponse<T>> {
    return this.executeRequest("DELETE", path, data);
  }

  public static async rawRequest<T>(
    method: string,
    path: string,
    data?: unknown
  ): Promise<T> {
    let contentType = "";
    if (data instanceof FormData) {
      contentType = "multipart/form-data";
    } else if (typeof data !== "undefined") {
      contentType = "application/json";
      data = JSON.stringify(data);
    }

    const token = localStorage.getItem("access_token");
    const headers = new Headers();

    if (token) {
      headers.append("Authorization", `Bearer ${token}`);
    }

    if (contentType) {
      headers.append("Content-Type", contentType);
    }

    const resp = await fetch(path, {
      method,
      headers,
      body: data as BodyInit,
    });

    if (resp.ok && resp.status === 204) {
      // OK, but no content.
      // We expect the caller to know the server will not return any data.
      return (null as unknown) as T;
    } else if (resp.ok) {
      return resp.json() as Promise<T>;
    } else if (resp.status === 422) {
      const data = await resp.json();

      if (PydanticError.is(data)) {
        throw new PydanticError(data);
      }
    }

    throw new Error(`rawRequest failed.`);
  }

  protected executeRequest<T>(
    method: string,
    path: string,
    data?: any
  ): Promise<ApiResponse<T>> {
    const token = localStorage.getItem("access_token");

    const request = new XMLHttpRequest();
    request.open(method, path, true);
    request.setRequestHeader("Accepts", "application/json");

    if (token !== null) {
      request.setRequestHeader("Authorization", `Bearer ${token}`);
    }
    if (data !== undefined && !(data instanceof FormData)) {
      request.setRequestHeader("Content-Type", "application/json");
    }

    const promise = new Promise<ApiResponse<T>>((resolve, reject) => {
      request.onload = function () {
        // FIXME: Typing on responseObject is invalid.
        let responseObject = null;
        try {
          if (this.status >= 200 && this.status != 204) {
            responseObject = JSON.parse(this.response);
          }
        } catch (e) {
          console.error("Could not parse API response:", e);
        }

        let response = new ApiResponse<T>();
        if (responseObject !== null) {
          response.result = responseObject;
        }

        if (this.status >= 200 && this.status < 400) {
          resolve(response);
        } else {
          if (this.status === 422 && PydanticError.is(responseObject)) {
            response.pydanticError = new PydanticError(responseObject);
          }

          response.error = true;
          response.errorCode = this.status;

          wrapApiError(response, this, responseObject);

          reject(response);
        }
      };

      request.onerror = () => {
        let response = new ApiResponse();
        response.error = true;
        response.errorIdentifier = "network_error";
        response.errorMessage = "Network connection error";

        reject(response);
      };
    });

    if (data instanceof FormData) {
      request.send(data);
    } else {
      request.send(data !== undefined ? JSON.stringify(data) : null);
    }

    return promise;
  }
}

const TOKEN_EXPIRED_MESSAGE = "Token expired, please login again.";
const INSUFFICIENT_PERMISSION_MESSAGE = "No permission";

function wrapApiError(
  apiResponse: ApiResponse<any>,
  response: XMLHttpRequest,
  responseObject: object | null
) {
  apiResponse.errorIdentifier =
    responseObject !== null ? responseObject["error"] : "unknown_error";

  switch (apiResponse.errorIdentifier) {
    case "invalid_access_token":
      apiResponse.errorMessage = TOKEN_EXPIRED_MESSAGE;
      break;
    case "insufficient_permissions":
      apiResponse.errorMessage = INSUFFICIENT_PERMISSION_MESSAGE;
      break;
    default:
      if (responseObject !== null) {
        apiResponse.errorMessage = responseObject["error"];
      } else {
        apiResponse.errorMessage = response.response;
      }
      break;
  }
}
