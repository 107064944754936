import { ref } from "@vue/reactivity";

export function useTimer(complete: () => void, initialTimeout: number = 60) {
  const countdownId: number = setInterval(countdownTimerTriggered, 1000);
  const secondsLeft = ref(initialTimeout);

  function countdownTimerTriggered() {
    secondsLeft.value--;
    if (secondsLeft.value <= 0) {
      complete();
      clearInterval(countdownId);
    }
  }

  function stop() {
    clearInterval(countdownId);
  }

  return { secondsLeft, stop };
}
