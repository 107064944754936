import { useQuery } from "@tanstack/vue-query";
import { CheckoutCardResponse } from "../../scripts/api/checkout";
import { transactionsApi } from "../../scripts/api/transactions";
import { TransactionStatus } from "../../scripts/types";
import { computed, Ref } from "@vue/reactivity";
import { ComputedRef, ref } from "vue";
import { defineStore } from "pinia";
import { PurchaseApiRequest } from "../../scripts/store/purchase/requests";
import { ApiResponse } from "../../scripts/api/api";

export interface CardTransactionModalProps {
  price: string;
  terminal: Exclude<CheckoutCardResponse["terminal"], undefined>;
  transactionId: number;
}

export interface CardTransactionModalEmits {
  (e: "confirm", alreadyConfirmed: boolean): void;
  (e: "cancel"): void;
  (e: "retry"): void;
}

export const useCardTransactionStore = defineStore(
  "cardTransactionStore",
  () => {
    const pendingTransactionId = ref<number | undefined>(undefined);
    const originalRequest = ref<PurchaseApiRequest | undefined>(undefined);
    const terminal = ref<CheckoutCardResponse["terminal"] | undefined>(
      undefined
    );
    const price = ref<number | undefined>(undefined);

    function reset() {
      pendingTransactionId.value = undefined;
      originalRequest.value = undefined;
      terminal.value = undefined;
      price.value = undefined;
    }

    return {
      pendingTransactionId,
      originalRequest,
      terminal,
      price,
      reset,
    };
  }
);

export function usePendingTransaction(
  transactionId: Ref<number | undefined>
): ComputedRef<
  | { state: "loading" }
  | {
      state: "error";
      error: string;
    }
  | { transactionStatus: TransactionStatus; state: "loaded"; failed: boolean }
> {
  const { data, status, error } = useQuery({
    queryKey: ["transactions/status", transactionId],
    enabled: computed(() => !!transactionId.value),
    queryFn: async () => {
      if (!transactionId.value) {
        throw new Error("Transaction ID is undefined");
      }
      const res = await transactionsApi.getTransactionStatus(
        transactionId.value
      );
      return res.result;
    },
    retry: 2,
    refetchInterval: (value) => {
      if (value?.status === TransactionStatus.PENDING) {
        return 1000;
      }
      return false;
    },
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
  });

  return computed(() => {
    if (status.value === "loading") {
      return { state: "loading" };
    }

    if (status.value === "error") {
      if (ApiResponse.is(error.value)) {
        return {
          error: error.value.errorMessage || "Unknown error",
          state: "error",
        };
      }

      return {
        error: "Unknown error",
        state: "error",
      };
    }

    return {
      transactionStatus: data.value!.status,
      state: "loaded",
      failed:
        data.value!.status === TransactionStatus.DELETED ||
        data.value!.status === TransactionStatus.EXPIRED ||
        data.value!.status === TransactionStatus.CANCELLED,
    };
  });
}
