import { Account } from "../../types";
import { usePurchaseStore } from "../purchase";
import { PurchaseApiRequest } from "./requests";
import { CheckoutCardResponse } from "../../api/checkout";

export enum ModalState {
  None,
  Cash,
  Card,
  OtherReason,
  Other,
  Account,
  AccountPin,
  Topup,
  AccountTopup,
  ReaderAccount,
  ReaderPin,
  Loading,
}

export class ModalContext {
  id: number = -1;

  get modalView() {
    return "unknown";
  }
}

export class LoadingModalContext extends ModalContext {
  get modalView() {
    return "loading";
  }
}

export class ProductsModalContext extends ModalContext {
  totalPrice: number;

  constructor(totalPrice: number) {
    super();
    this.totalPrice = totalPrice;
  }
}

export class CheckoutCardModalContext extends ProductsModalContext {
  pendingTransactionID: number;
  originalRequest: PurchaseApiRequest;
  terminal: CheckoutCardResponse["terminal"];

  constructor(
    totalPrice: number,
    pendingTransactionID: number,
    originalRequest: PurchaseApiRequest,
    terminal: CheckoutCardResponse["terminal"]
  ) {
    super(totalPrice);
    this.pendingTransactionID = pendingTransactionID;
    this.originalRequest = originalRequest;
    this.terminal = terminal;
  }

  get modalView() {
    return "card";
  }
}

export class CheckoutCashModalContext extends ProductsModalContext {
  constructor(totalPrice: number) {
    super(totalPrice);
  }

  get modalView() {
    return "cash";
  }
}

export class CheckoutOtherModalContext extends ProductsModalContext {
  checkoutReason: string;

  constructor(checkoutReason: string, totalPrice: number) {
    super(totalPrice);
    this.checkoutReason = checkoutReason;
  }
  get modalView() {
    return "other";
  }
}

export class CheckoutOtherReasonModalContext extends ModalContext {
  get modalView() {
    return "otherReason";
  }
}

export class CheckoutAccountModalContext extends ProductsModalContext {
  accounts: Account[];
  title: string;

  constructor(
    accounts: Account[],
    totalPrice: number,
    title: string = "Select account"
  ) {
    super(totalPrice);
    this.accounts = accounts;
    this.title = title;
  }

  get modalView() {
    return "account";
  }

  async refresh() {
    const purchaseStore = usePurchaseStore();
    await purchaseStore.updateAccounts();
    this.accounts = purchaseStore.accounts;
  }
}

export class PinModalContext extends ModalContext {
  title: string;

  pinError = false;

  constructor(title: string) {
    super();
    this.title = title;
  }

  get modalView() {
    return "pin";
  }
}

export class TopupModalContext extends ModalContext {
  original: number;

  constructor(original: number) {
    super();
    this.original = original;
  }

  get modalView() {
    return "topup";
  }
}
