<template lang="pug">
.sidebar
  .shadow(@click="$emit('cancel')")

  .logo
    img(src="/img/via_mark.svg")
    a.title PoS

  sidebar-element(
    name="index",
    href="/pos",
    title="Shop",
    v-if="loginStore.user"
  )
    template(v-slot:icon)
      ShoppingCartOutlined

  sidebar-element(name="index", href="/login", title="Login", v-else="")

  sidebar-element(
    name="accountConfig",
    href="/account",
    title="Your account",
    :notifications="accountNotifications",
    v-if="loginStore.user && loginStore.user.accountId !== null"
  )
    template(v-slot:icon)
      UserOutlined

  sidebar-element(
    name="accountSetup",
    href="/setup",
    title="Account setup",
    v-if="loginStore.showAccountRequest"
  )

  sidebar-element(
    name="adminProducts",
    href="/admin/products",
    title="Products",
    v-if="loginStore.user && loginStore.user.isAdmin"
  )
    template(v-slot:icon)
      ShoppingOutlined
    sidebar-element.sub(
      name="adminCategories",
      href="/admin/categories",
      title="Categories"
    )
      template(v-slot:icon)
        TagsOutlined
    sidebar-element.sub(
      name="adminInvoices",
      href="/admin/invoices",
      title="Invoices"
    )
      template(v-slot:icon)
        FileTextOutlined
    sidebar-element.sub(
      name="adminRegisters",
      href="/admin/registers",
      title="Registers"
    )
      template(v-slot:icon)
        ShopOutlined
    sidebar-element.sub(
      name="adminBar",
      href="/admin/bar",
      title="Bar Management"
    )
      template(v-slot:icon)
        DashboardOutlined

  sidebar-element(
    name="adminAccounts",
    href="/admin/accounts",
    title="Accounts",
    v-if="loginStore.user && loginStore.user.isAdmin"
  )
    template(v-slot:icon)
      ContactsOutlined
    sidebar-element.sub(
      name="adminAccountRequests",
      href="/admin/account_requests",
      title="Account Requests"
    )
      template(v-slot:icon)
        UserAddOutlined
    sidebar-element.sub(name="adminUsers", href="/admin/users", title="Users")
      template(v-slot:icon)
        TeamOutlined

  sidebar-element(
    name="admin",
    href="/admin/transactions",
    title="Admin",
    v-if="loginStore.user && loginStore.user.isAdmin"
  )
    template(v-slot:icon)
      SettingOutlined
    sidebar-element.sub(
      name="adminTransactions",
      href="/admin/transactions",
      title="Transactions"
    )
      template(v-slot:icon)
        ProfileOutlined
    sidebar-element.sub(
      name="adminDirectDebit",
      href="/admin/direct_debit",
      title="Direct Debit"
    )
      template(v-slot:icon)
        CreditCardOutlined
    sidebar-element.sub(
      name="adminExactOnline",
      href="/admin/exact",
      title="Exact Online"
    )
      template(v-slot:icon)
        BankOutlined
    sidebar-element.sub(
      name="adminAuditLogs",
      href="/admin/audit_logs",
      title="Audit logs"
    )
      template(v-slot:icon)
        FileProtectOutlined
    sidebar-element.sub(
      name="adminPaymentsIdeal",
      href="/admin/ideal",
      title="iDEAL payments"
    )
      template(v-slot:icon)
        EuroOutlined

  sidebar-element(
    name="adminSettings",
    href="/admin/settings",
    title="Settings",
    v-if="loginStore.user && loginStore.user.isAdmin"
  )
    template(v-slot:icon)
      ControlOutlined

  sidebar-element(name="About", href="/about", title="About")
    template(v-slot:icon)
      ReadOutlined

  .sidebar-footer
    .sidebar-footer-row(v-if="username")
      div {{ username }}
    .sidebar-footer-row
      img(
        :src="virtualKeyboardStore.enabled ? '/img/keyboard_fill.svg' : '/img/keyboard.svg'",
        @click="virtualKeyboardStore.enabled = !virtualKeyboardStore.enabled"
      )
      img.logout-icon(src="/img/logout.svg", @click="$emit('logout')")
</template>

<script setup lang="ts">
import SidebarElement from "./sidebar_element.vue";
import { useLoginStore } from "../scripts/store/login";
import { computed } from "vue";
import { useVirtualKeyboardStore } from "../scripts/composables/form";

const loginStore = useLoginStore();
const virtualKeyboardStore = useVirtualKeyboardStore();
const username = computed(() => loginStore.user?.username ?? "");
const accountNotifications = computed(() => {
  let num = 0;
  if (loginStore.requireSettingAccountPin) {
    num += 1;
  }

  if (loginStore.requireTfa) {
    num += 1;
  }

  return num;
});
</script>

<style lang="sass" scoped>
@import "../styles/common.sass"
@import "../styles/_fonts.sass"
@import "../styles/_colors.sass"
@import "../styles/_mixins.sass"

$animation: 300ms cubic-bezier(.73,0,.25,1)

.sidebar
    @include mix-user-select(none)

    display: flex
    flex-flow: column nowrap
    height: 100%
    width: 200px

    padding: 0

    background-color: $flat-blue-4
    color: #ffffff
    font-size: 14px

    box-sizing: border-box

    position: relative
    z-index: 10

    overflow-x: hidden
    overflow-y: auto

    *:last-child
        margin-bottom: 1px

    @media (max-width: 1165px)
        position: fixed
        left: -200px

        transition: box-shadow $animation, left $animation

        &.show
            left: 0
            box-shadow: -20px 0 80px 80px rgba(0, 0, 0, 0.3)

    .shadow
        position: fixed
        top: 0
        left: 0
        right: 0
        bottom: 0

        background-color: rgba(0, 0, 0, 0.6)

        visibility: hidden
        opacity: 0

        transition: visibility $animation, opacity $animation, left $animation

    &.show .shadow
        visibility: visible
        opacity: 1

        left: 200px

    .menu-title
      color: white

.buttons
    padding-bottom: 3rem
    overflow-y: auto

.sidebar-footer
  margin-top: auto

.sidebar-footer-row
    display: flex
    flex-direction: row
    justify-content: space-between

    background-color: $flat-blue-4

    padding: 0 0.5rem 0 1rem
    border-top: 1px solid rgba(255, 255, 255, 0.1)

    height: 3em
    min-height: 3rem
    line-height: 3rem

    align-items: center

    width: 100%

    font-weight: 600

    &:hover
        background-color: transparent
        text-decoration: none

    div
        overflow: hidden
        text-overflow: ellipsis
        line-height: 3rem

    img
        cursor: pointer
        width: 2em
        line-height: 3rem

.logo
    display: flex
    margin: 1em auto 0.5em auto

    img
        width: 2em
        height: 2em
        margin-right: 0.5em

    a.title
        font-family: $font-caption2
        font-weight: 200
        font-size: 2em
        line-height: 1.2em
        text-align: center
        color: rgb(255, 255, 255)
        vertical-align: bottom
</style>
