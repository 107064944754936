<template lang="pug">
.cart(@click="$emit('cancel')", :class="{ show: show }")
  .inner(@click.stop, :class="{ show: show }")
    .table-container
      table
        colgroup
          col.column--adjust(style="width: 48px")
          col.column--adjust(style="width: 45px")
          col.column--amount(style="width: 45px")
          col.column--name(style="width: auto")
          col.column--price(style="width: 75px")

        tbody
          template(v-for="(cartEntry, index) in cartEntries")
            tr.entry__row(
              :data-id="index",
              :data-price="cartEntry.product.price",
              :data-color="productCategory[cartEntry.product.id].color"
            )
              td.entry__adjust(@click="removeFromCart(index)") -
              td.entry__adjust(@click="addFromCart(index)") +
              td.entry__amount {{ cartEntry.count }}
              td.entry__name
                | {{ cartEntry.product.name }}
                template(v-if="cartEntry.product.deposit")
                  br
                  .entry__deposit Return packaging to via
              td.entry__price {{ priceStr(cartEntry.product.price * cartEntry.count, false) }}

          tr(v-if="topupAmount !== null")
            td.entry__adjust(@click="$emit('clearTopup')") -
            td.entry__adjust
            td.entry__amount
            td.entry__name {{ 'Opwaarderen ' + (topupAccount ? topupAccount.name : '') }}
            td.entry__price {{ priceStr(topupAmount) }}

    Transition(appear, name="appear")
      PendingCardTransactionBar(v-if="!!pendingTransactionId")

    div
      checkout-button(
        v-show="showTopup",
        type="button",
        @click="$emit('topup')",
        text="Opwaarderen",
        :disabled="topupAmount !== null"
      )

    .total
      span.label Total:
      span.price(:class="{ insufficient: insufficientBalance }") {{ priceStr(price) }}

    template(v-if="showPersonalBalance")
      .total
        span.label Account balance{{ accountSelfStore.creditAccount ? ' (credit)' : '' }}:
        span.price {{ priceStr(accountSelfStore.balance) }}

    template(
      v-if="accountSelfStore.sepaDate && accountSelfStore.sepaBalance !== undefined"
    )
      .total
        span.label Direct debit due {{ localeDate(accountSelfStore.sepaDate) }}:
        span.price {{ priceStr(accountSelfStore.sepaBalance) }}

    .checkout-buttons
      checkout-button(
        :disabled="cartEntries.length === 0 && topupAmount === null",
        v-if="loginStore.hasCapability('PAYMENT_CASH')",
        @click="$emit('checkout', 'cash')",
        text="Cash"
      )
        template(v-slot:icon)
          EuroOutlined

      checkout-button(
        :disabled="(cartEntries.length === 0 && topupAmount === null) || (!!pendingTransactionId && allowNewTransaction)",
        v-if="loginStore.hasCapability('PAYMENT_CARD')",
        @click="$emit('checkout', 'card')",
        text="Card"
      )
        template(v-slot:icon)
          CreditCardOutlined

      checkout-button(
        :disabled="cartEntries.length === 0 || topupAmount !== null || insufficientBalance",
        v-if="loginStore.hasCapability('PAYMENT_ACCOUNT_AUTHED') || loginStore.hasCapability('PAYMENT_ACCOUNT_OTHER')",
        @click="$emit('checkout', 'account')",
        text="Account"
      )
        template(v-slot:icon)
          UserOutlined

      checkout-button(
        :disabled="cartEntries.length === 0 || topupAmount !== null || insufficientBalance",
        v-if="loginStore.hasCapability('PAYMENT_ACCOUNT_SELF')",
        @click="$emit('checkout', 'account_self')",
        text="Pay with your account"
      )
        template(v-slot:icon)
          UserOutlined

      checkout-button(
        :disabled="cartEntries.length === 0 || topupAmount !== null",
        v-if="loginStore.hasCapability('PAYMENT_OTHER')",
        @click="$emit('checkout', 'other')",
        text="Other"
      )
        template(v-slot:icon)
          FormOutlined
</template>

<script setup lang="ts">
import type { Account, Category } from "../../scripts/types";
import { CartEntry } from "../../scripts/types";
import { useLoginStore } from "../../scripts/store/login";
import { usePurchaseStore } from "../../scripts/store/purchase";
import { debounce, localeDate, priceStr } from "../../scripts/util";
import { useAccountSelfStore } from "../../scripts/store/account";
import PendingCardTransactionBar from "../card_transactions/PendingCardTransactionBar.vue";
import CheckoutButton from "./CheckoutButton.vue";
import { computed, onMounted, onUnmounted } from "vue";
import {
  useCardTransactionStore,
  usePendingTransaction,
} from "../card_transactions/CardTransactionBase";
import { storeToRefs } from "pinia";

interface CartProps {
  show: boolean;
  cartEntries: CartEntry[];
  topupAmount: number | null;
  topupAccount: Account | null;
  price: number;
  showTopup: boolean;
  categories: Category[];
}

const props = withDefaults(defineProps<CartProps>(), { show: false });

const loginStore = useLoginStore();
const purchaseStore = usePurchaseStore();
const accountSelfStore = useAccountSelfStore();

const { pendingTransactionId } = storeToRefs(useCardTransactionStore());
const query = usePendingTransaction(pendingTransactionId);
const allowNewTransaction = computed(
  () => query.value.state === "loaded" && !query.value.failed
);

function removeFromCart(index: number) {
  const cartEntry = props.cartEntries[index];
  if (--cartEntry.count === 0) {
    props.cartEntries.splice(index, 1);
  }
}

function addFromCart(index: number) {
  const cartEntry = props.cartEntries[index];
  ++cartEntry.count;
}

const showPersonalBalance = computed(() => {
  return loginStore.hasCapability("PAYMENT_ACCOUNT_SELF");
});

const insufficientBalance = computed(() => {
  return (
    loginStore.hasCapability("PAYMENT_ACCOUNT_SELF") &&
    !accountSelfStore.creditAccount &&
    accountSelfStore.balance != null &&
    props.price > accountSelfStore.balance
  );
});

const checkout = debounce(() => {
  const purchaseStore = usePurchaseStore();
  purchaseStore.purchaseWith("account_reader");
}, 50);

function handleKeyEvent(event: KeyboardEvent) {
  if (!purchaseStore.cartEntries.length) return;
  if (event.key === "$" || purchaseStore.cardSecret.length) {
    event.preventDefault();
    purchaseStore.cardSecret += event.key;
    checkout();
  }
}

const productCategory = computed(() => {
  let productIdToCategories = new Set();
  props.categories.forEach((category) => {
    category.products.forEach((product) => {
      productIdToCategories[product.id] = category;
    });
  });
  return productIdToCategories;
});

onMounted(() => {
  document.addEventListener("keypress", handleKeyEvent);
});

onUnmounted(() => {
  document.removeEventListener("keypress", handleKeyEvent);
});
</script>

<style lang="sass" scoped>
@import "../../styles/colors"
@import "../../styles/mixins"
@import "../../styles/vars"

.cart
    @include mix-user-select(none)

    z-index: 10

    // When collapsed
    @include screen-small
        visibility: hidden

        position: absolute
        left: 0
        top: 0
        right: 0
        bottom: 0

        margin-top: 0 !important

        background-color: rgba(0, 0, 0, 0.0)
        transition: background-color 0.3s ease-out, visibility 0.3s ease-out

        &.show
            visibility: visible
            background-color: rgba(0, 0, 0, 0.6)

    // For the top navbar
    @media (max-width: 1165px)
        margin-top: 3.5rem

    .inner
        width: 400px
        height: 100%

        border-left: 1px solid #dddddd

        display: flex
        flex-direction: column

        font-size: 1.1em
        background-color: #f2f2f6

        .table-container
            flex: 1
            overflow-y: auto

        // When collapsed
        @include screen-small
            max-width: 80%
            margin-top: 0

            position: absolute
            top: 0
            right: 0
            bottom: 0

            transform: translateX(400px)

            transition: transform 0.2s ease-out

            border-left: 0

            &.show
                box-shadow: 10px 0 30px 15px rgba(0, 0, 0, 0.2)
                transform: translateX(0)


.container
    flex-grow: 1
    overflow-y: auto

table
    table-layout: fixed
    border-collapse: collapse
    margin-bottom: 1rem
    width: 100%

    tr
        border-left-width: 6px
        border-left-style: solid

    td
        padding: 8px 6px 8px 6px

        border-color: #dddddd
        background-color: #ffffff
        vertical-align: top

@each $key, $color in $product-colors
    .entry__row[data-color="#{$key}"]
        border-left-color: $color

.entry__adjust
    font-size: 1.2em
    border-right: 1px solid #dddddd

    text-align: center
    cursor: pointer

    padding-top: 4.5px
    margin-left: 6px

    user-select: none

.entry__adjust:active
    background: #e0e0e0

.entry__amount
    text-align: center
    vertical-align: top

.entry__name
    overflow-wrap: break-word
    padding-left: 0
    padding-right: 0

.entry__deposit
    color: $flat-gray-3

.entry__price
    text-align: right
    padding-right: 14px

.total
    line-height: 50px
    height: 50px
    width: 100%
    background-color: #ffffff
    border-top: 1px solid #dddddd
    padding: 0 15px

    .label
        color: #333

    .price
        float: right
        line-height: 50px
        text-align: right
        font-weight: 700

        &.insufficient
            color: $flat-red-2


.checkout-buttons
    width: 100%

.appear-enter-active, .appear-leave-active
    transition: opacity 0.3s

.appear-enter, .appear-leave-to
    opacity: 0

@keyframes appear
    from
        opacity: 0
    to
        opacity: 1
</style>
