import { Api, ApiResponse, objectToSearchParamString } from "./api";
import { Account } from "../types";
import { NumberFilterString, PaginatedResponse } from "./pagination";
import type { AccountRequest } from "../types";

export class AccountSepaResponse {
  iban: string;
  bic: string;
  mandateId: string;
  signDate: string;
  balance: number | null;
  execDate: string | null;
}

export class AccountSelfResponse {
  balance: number;
  creditAccount: boolean;
  sepa: AccountSepaResponse | null;
  account: Account;
}

export class AccountsResponse {
  accounts: Account[];
}

export interface LedgerAccountsResponse {
  id: number;
  name: string;
  incomeLedger: boolean;
  ledgerCode: string | null;
  costCenterCode: string | null;
  vatCode: string | null;
  associatedCheckoutMethod: string;
}

export class AccountUpgradeResponse {
  paymentUrl: string;
  paymentId: string;
  accountId: number;
}

export class AccountRequestStatus {
  account_request: AccountRequest;
}

export class AccountRequests {
  account_requests: AccountRequest[];
}

export interface AccountExactLedger {
  id: number;
  name: string;
  incomeLedger: boolean;
  costCenterCode: string;
  vatCode: string;
  ledgerCode: string;
}

export interface AdminAccountsRequest {
  page?: number;
  perPage?: number;
  idFilter?: NumberFilterString;
  typeFilter?: string;
  allowNegativeFilter?: boolean;
  deletedFilter?: boolean;
  balanceFilter?: NumberFilterString;
  search?: string;
}

export interface CreateUpdateAccountRequest {
  name: string;
  description?: string;
  pin?: string;
  type: Account["type"];
  allowNegativeBalance?: boolean | null;
  iban?: string | null;
  bic?: string | null;
  mandateId?: string | null;
  signDate?: string | null;
}

export interface BalancesAtDate {
  balanceAt: {
    accountList: {
      accountName: string;
      currentBalance: number;
      oldBalance: number;
    }[];
    totalPositive: number;
    totalNegative: number;
    totalBalance: number;
  };
}

export class AccountsApi extends Api {
  public async createAccount(account: CreateUpdateAccountRequest) {
    return this.post<Account>("/api/admin/accounts/", account);
  }
  async updateAccount(accountId: number, account: CreateUpdateAccountRequest) {
    return this.put<unknown>(`/api/admin/accounts/${accountId}`, account);
  }
  async patchAccount(
    accountId: number,
    account: Partial<CreateUpdateAccountRequest>
  ) {
    return this.patch<Account>(`/api/admin/accounts/${accountId}/`, account);
  }
  async adminAccount(accountId: number) {
    return this.get<Account>(`/api/admin/accounts/${accountId}/`);
  }
  public async accounts(): Promise<ApiResponse<AccountsResponse>> {
    return this.get<AccountsResponse>("/api/accounts/");
  }

  async adminAccounts(
    params: AdminAccountsRequest
  ): Promise<ApiResponse<PaginatedResponse<Account>>> {
    const { page = 1, perPage = 15, ...filters } = params;
    let url = `/api/admin/accounts/?page=${page}&perPage=${perPage}`;

    for (const [queryParam, value] of Object.entries(filters)) {
      url += `&${queryParam}=${value}`;
    }

    return this.get<PaginatedResponse<Account>>(url);
  }

  public async adminUpgrade(
    accountId: number,
    method: "cash" | "card" | "transfer" | "other",
    amountInCents: number,
    reason: string,
    nonce: string
  ) {
    return this.post<{ transactionId: number; account: Account }>(
      `/api/admin/accounts/${accountId}/upgrade/`,
      {
        amount: amountInCents,
        method,
        reason,
        nonce,
      }
    );
  }

  public async upgrade(
    amount: number
  ): Promise<ApiResponse<AccountUpgradeResponse>> {
    return this.post<AccountUpgradeResponse>(`/api/account/upgrade`, {
      amount: amount,
    });
  }

  public async accountDetails(): Promise<ApiResponse<AccountSelfResponse>> {
    return this.get<AccountSelfResponse>("/api/account/");
  }

  public async accountRequestPending(): Promise<
    ApiResponse<AccountRequestStatus>
  > {
    return this.get<AccountRequestStatus>("/api/account_request/pending");
  }

  public async requestAccount(
    pin: string
  ): Promise<ApiResponse<AccountRequestStatus>> {
    return this.post<AccountRequestStatus>("/api/account_request/request", {
      pin: pin,
    });
  }

  public async changePin(pin: string): Promise<ApiResponse<void>> {
    return this.post<void>("/api/account/pin", {
      pin: pin,
    });
  }

  public async accountRequests(): Promise<ApiResponse<AccountRequests>> {
    return this.get<AccountRequests>("/api/admin/account_requests");
  }

  public async accountRequestSetStatus(
    account_request_id: number,
    status: "approve" | "decline"
  ): Promise<ApiResponse<AccountRequest>> {
    return this.post<AccountRequest>(
      `/api/admin/account_requests/${account_request_id}/status`,
      {
        status: status,
      }
    );
  }

  public async ledgers({
    page = 1,
    perPage = 15,
  }): Promise<ApiResponse<PaginatedResponse<LedgerAccountsResponse>>> {
    return this.get<PaginatedResponse<LedgerAccountsResponse>>(
      `/api/admin/accounts/ledgers/?page=${page}&perPage=${perPage}`
    );
  }

  async deleteAccount(accountId: number): Promise<unknown> {
    return this.delete(`/api/admin/accounts/${accountId}/`, undefined);
  }

  public async saveLedger(
    accountLedger: AccountExactLedger
  ): Promise<ApiResponse<AccountExactLedger>> {
    const data: Omit<AccountExactLedger, "id" | "name"> = (({
      id,
      name,
      ...o
    }) => o)(accountLedger);
    return this.put<AccountExactLedger>(
      `/api/admin/accounts/ledgers/${accountLedger.id}/`,
      data
    );
  }

  public async balancesAtDate(balanceDate: string): Promise<BalancesAtDate> {
    let url = `/api/admin/accounts/balance/`;
    const queryString = objectToSearchParamString({
      date: balanceDate,
    });

    if (queryString) {
      url += "?" + queryString;
    }

    return Api.rawRequest("GET", url);
  }
}

export const accountsApi = new AccountsApi();
