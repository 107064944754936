import {
  Account,
  CartEntry,
  Category,
  CheckoutReason,
  Product,
} from "../types";
import { generateUuid, priceStr, showError } from "../util";
import { useSnackbarStore } from "./snackbar";
import { ApiResponse, isApiResponse } from "../api/api";
import { checkoutApi, CheckoutResponse } from "../api/checkout";
import { accountsApi } from "../api/accounts";
import { transactionsApi } from "../api/transactions";
import { productsApi } from "../api/products";
import { useAccountSelfStore } from "./account";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useLoginStore } from "./login";

import {
  cardCheckoutStart,
  confirmCheckout,
  PurchaseApiRequest,
} from "./purchase/requests";
import {
  CheckoutAccountModalContext,
  CheckoutCardModalContext,
  CheckoutCashModalContext,
  CheckoutOtherModalContext,
  CheckoutOtherReasonModalContext,
  LoadingModalContext,
  ModalContext,
  ModalState,
  PinModalContext,
  TopupModalContext,
} from "./purchase/modals";
import { useCardTransactionStore } from "../../components/card_transactions/CardTransactionBase";

// Update all products (and accounts if it has the capability) every 60 seconds.
const UPDATE_ACCOUNTS_AND_PRODUCTS_INTERVAL = 60000;

export const usePurchaseStore = defineStore("purchaseStore", () => {
  const accountSelfStore = useAccountSelfStore();
  const snackbarStore = useSnackbarStore();
  const loginStore = useLoginStore();

  const categories = ref<Category[]>([]);
  const accounts = ref<Account[]>([]);
  const reasons = ref<CheckoutReason[]>([]);

  const showConfirmPurchaseCheckmark = ref<boolean>(false);
  const confirmPurchaseCheckmarkText = ref<string>("");

  const modalStack = ref<ModalContext[]>([]);
  const cartEntries = ref<CartEntry[]>([]);
  const topupAmount = ref<number | null>(null);
  const selectedTopupAccount = ref<Account | null>(null);
  const checkoutReason = ref<string | null>(null);

  const searchQuery = ref<string>("");
  const showCart = ref<boolean>(false);

  const state = ref<ModalState>(ModalState.None);
  const selectedAccount = ref<Account | null>(null);
  const selectedAccountPassword = ref<string | null>(null);
  const cardSecret = ref<string>("");

  const modalStackId = ref<number>(0);
  const blockInput = ref<boolean>(false);

  // Nonces are used to make sure that checkout requests are never executed
  // twice. Therefor we generate the nonce pre-checkout and replace it after
  // the request has been successful.
  const nonce = ref<string>(generateUuid());

  async function initialize() {
    await updateProducts();
    setInterval(
      async () => await updateProducts(),
      UPDATE_ACCOUNTS_AND_PRODUCTS_INTERVAL
    );

    if (
      loginStore.hasCapability("PAYMENT_ACCOUNT_AUTHED") ||
      loginStore.hasCapability("PAYMENT_ACCOUNT_OTHER")
    ) {
      await updateAccounts();
      setInterval(
        async () => await updateAccounts(),
        UPDATE_ACCOUNTS_AND_PRODUCTS_INTERVAL
      );
    }

    if (loginStore.hasCapability("PAYMENT_OTHER")) {
      await updateReasons();
      setInterval(
        async () => await updateReasons(),
        UPDATE_ACCOUNTS_AND_PRODUCTS_INTERVAL
      );
    }

    if (loginStore.hasCapability("PAYMENT_ACCOUNT_SELF")) {
      try {
        await accountSelfStore.loadAccount();
      } catch (e) {
        // Ignore no_linked_account error, as it's expected when the user has no account.
        // login.ts will redirect the user to the setup page in that case. Other errors
        // are shown as usual.
        if (e.errorIdentifier !== "no_linked_account") {
          showError(e);
        }
      }
    }
  }

  function clear(clearCart = true) {
    selectedAccount.value = null;
    selectedAccountPassword.value = null;
    selectedTopupAccount.value = null;
    modalStack.value = [];
    if (clearCart) {
      cartEntries.value = [];
      topupAmount.value = null;
    }
    state.value = ModalState.None;
    searchQuery.value = "";
    checkoutReason.value = null;
    cardSecret.value = "";
  }

  const totalCount = computed<number>(() =>
    cartEntries.value.reduce((sum, entry) => sum + entry.count, 0)
  );

  const totalPrice = computed<number>(() => {
    const fn = (acc: number, entry: CartEntry) =>
      acc + entry.count * entry.product.price;
    return cartEntries.value.reduce(fn, 0) + (topupAmount.value || 0);
  });

  function addToCart(product: Product) {
    if (blockInput.value) return;

    let duplicateFound = false;
    for (const cartEntry of cartEntries.value) {
      if (cartEntry.product.id === product.id) {
        cartEntry.count += 1;
        duplicateFound = true;
        break;
      }
    }

    if (!duplicateFound) {
      cartEntries.value.push(new CartEntry(product, 1));
    }
  }

  async function performCardCheckoutRequest(request: PurchaseApiRequest) {
    try {
      const isBar = loginStore.hasCapability("PAYMENT_ACCOUNT_OTHER");
      blockInput.value = true;
      state.value = ModalState.Loading;
      pushModal(new LoadingModalContext());

      const { result } = await cardCheckoutStart(request);

      popModal(); // Loading modal;
      const modal = new CheckoutCardModalContext(
        totalPrice.value,
        result.transaction_id,
        request,
        result.terminal
      );

      if (isBar && result.terminal) {
        const cardTransactionStore = useCardTransactionStore();
        cardTransactionStore.pendingTransactionId = result.transaction_id;
        cardTransactionStore.terminal = result.terminal;
        cardTransactionStore.originalRequest = request;
        cardTransactionStore.price = totalPrice.value;

        state.value = ModalState.None;
        clear();
        showCart.value = false;
        nonce.value = generateUuid();
      } else {
        pushModal(modal);
        state.value = ModalState.Card;
      }
    } catch (e) {
      showError(e);
      popModal();
      state.value = ModalState.None;
    }

    blockInput.value = false;
  }

  const showTopup = computed<boolean>(() =>
    loginStore.hasCapability("PAYMENT_ACCOUNT_OTHER")
  );

  function topupClicked() {
    if (blockInput.value) return;

    if (state.value == ModalState.None) {
      state.value = ModalState.Topup;
      pushModal(new TopupModalContext(totalPrice.value));
    }
  }

  function clearTopupClicked() {
    topupAmount.value = null;
  }

  // Start of purchase
  function purchaseWith(purchaseType: string) {
    if (blockInput.value) return;

    if (state.value != ModalState.None) return;

    let modal: ModalContext | null = null;
    if (purchaseType == "cash") {
      modal = new CheckoutCashModalContext(totalPrice.value);
      state.value = ModalState.Cash;
    } else if (purchaseType == "card") {
      let request = new PurchaseApiRequest(
        "card",
        nonce.value,
        cartEntries.value
      );

      if (
        loginStore.hasCapability("PAYMENT_ACCOUNT_OTHER") &&
        topupAmount.value !== null &&
        selectedTopupAccount.value !== null
      ) {
        request.upgradeAccount = selectedTopupAccount.value;
        request.upgradeAccountAmount = topupAmount.value;
      }

      performCardCheckoutRequest(request);
    } else if (purchaseType == "other") {
      modal = new CheckoutOtherReasonModalContext();
      state.value = ModalState.OtherReason;
    } else if (purchaseType == "account_self") {
      let request = new PurchaseApiRequest(
        "account/self",
        nonce.value,
        cartEntries.value
      );
      performCheckoutRequest(request);
    } else if (purchaseType == "account") {
      modal = new CheckoutAccountModalContext(accounts.value, totalPrice.value);
      state.value = ModalState.Account;
    } else if (purchaseType == "account_reader") {
      let request = new PurchaseApiRequest(
        "account/reader",
        nonce.value,
        cartEntries.value
      );
      request.cardSecret = cardSecret.value;
      performCheckoutRequest(request);
    } else {
      throw new Error("Unknown purchase method (" + purchaseType + ")");
    }

    if (modal != null) {
      pushModal(modal);
    }
  }

  // Confirming some dialog.
  async function modalConfirmed(modal: ModalContext, confirmArguments) {
    if (blockInput.value) return;

    let request: PurchaseApiRequest | null = null;
    switch (state.value) {
      case ModalState.None:
        break;
      case ModalState.Loading:
        break;
      case ModalState.Card:
        if (modal instanceof CheckoutCardModalContext) {
          blockInput.value = true;

          if (confirmArguments.length === 0 || !confirmArguments[0]) {
            // Only finish the transaction if the user can confirm it.
            // Otherwise, it will be finished with the card terminal API.
            try {
              await transactionsApi.finishCardTransaction(
                modal.pendingTransactionID
              );
            } catch (e) {
              showError(e);
            }
          }

          firePurchaseConfirmedCheckmark(totalPrice.value, null);

          if (loginStore.hasCapability("PAYMENT_UNDO")) {
            fireUndoToast(
              totalPrice.value,
              modal.originalRequest,
              modal.pendingTransactionID
            );
          }

          blockInput.value = false;
          clear();
          showCart.value = false;
          nonce.value = generateUuid();
        } else {
          throw new Error("Should not be possible");
        }
        break;
      case ModalState.Cash:
        request = new PurchaseApiRequest(
          "cash",
          nonce.value,
          cartEntries.value
        );

        if (
          loginStore.hasCapability("PAYMENT_ACCOUNT_OTHER") &&
          topupAmount.value !== null &&
          selectedTopupAccount.value !== null
        ) {
          request.upgradeAccount = selectedTopupAccount.value;
          request.upgradeAccountAmount = topupAmount.value;
        }
        break;
      case ModalState.OtherReason:
        checkoutReason.value = confirmArguments[0];
        if (checkoutReason.value === null) throw new Error();

        state.value = ModalState.Other;
        pushModal(
          new CheckoutOtherModalContext(checkoutReason.value, totalPrice.value)
        );
        break;
      case ModalState.Other:
        // Other (todo: with reason)
        request = new PurchaseApiRequest(
          "other",
          nonce.value,
          cartEntries.value
        );
        request.checkoutReason = checkoutReason.value;
        break;
      case ModalState.Account:
        selectedAccount.value = confirmArguments[0];
        if (selectedAccount.value === null) throw new Error();

        // We're can skip the PIN modal with this permission
        if (loginStore.hasCapability("PAYMENT_ACCOUNT_OTHER")) {
          let request = new PurchaseApiRequest(
            "account",
            nonce.value,
            cartEntries.value
          );
          request.account = selectedAccount.value;
          performCheckoutRequest(request);
        } else {
          state.value = ModalState.AccountPin;
          pushModal(new PinModalContext(selectedAccount.value.name));
        }
        break;
      case ModalState.AccountPin:
        let pinModal = modal as PinModalContext;
        pinModal.pinError = false;

        selectedAccountPassword.value = confirmArguments[0];

        request = new PurchaseApiRequest(
          "account",
          nonce.value,
          cartEntries.value
        );
        if (selectedAccount.value == null) throw new Error();
        request.account = selectedAccount.value;
        request.accountPassword = selectedAccountPassword.value;
        break;
      case ModalState.Topup:
        topupAmount.value = confirmArguments[0];

        state.value = ModalState.AccountTopup;
        pushModal(
          new CheckoutAccountModalContext(
            accounts.value,
            totalPrice.value,
            "Rekening opwaarderen"
          )
        );

        break;
      case ModalState.AccountTopup:
        // Go back to the normal interface, add topup amount in cart.
        selectedTopupAccount.value = confirmArguments[0];
        popModal();
        popModal();
        state.value = ModalState.None;
        break;
      case ModalState.ReaderAccount:
        selectedAccount.value = confirmArguments[0];
        if (selectedAccount.value === null) throw new Error();

        // TODO Should we skip pin to register card when user
        //  has RegisterCap.PAYMENT_ACCOUNT_OTHER?
        state.value = ModalState.ReaderPin;
        pushModal(new PinModalContext(selectedAccount.value.name));
        break;
      case ModalState.ReaderPin:
        selectedAccountPassword.value = confirmArguments[0];
        performRegisterCardRequest();
        break;
      default:
        const enumExhaustCheck: never = state.value;
        throw new Error(`Unhandled ModalState ${state.value}`);
    }

    if (request != null) {
      performCheckoutRequest(request);
    }
  }

  function modalRetry(modal: ModalContext): void {
    if (blockInput.value) return;

    if (state.value != ModalState.Card) {
      console.warn("Wrong modal retrying");
      return;
    }

    popModal();
    const request = (modal as CheckoutCardModalContext).originalRequest;
    request.nonce = generateUuid();

    performCardCheckoutRequest(request);
  }

  function modalCancelled(modal: ModalContext, cancelArguments) {
    if (blockInput.value) return;

    switch (state.value) {
      case ModalState.None:
        break;
      case ModalState.Loading:
        break;

      case ModalState.Card:
        topupAmount.value = null;
        selectedTopupAccount.value = null;
        popModal();
        state.value = ModalState.None;
        nonce.value = generateUuid();
        break;
      case ModalState.Cash:
      case ModalState.OtherReason:
      case ModalState.Account:
      case ModalState.Topup:
        topupAmount.value = null;
        selectedTopupAccount.value = null;
        popModal();
        state.value = ModalState.None;
        break;
      case ModalState.Other:
        checkoutReason.value = null;
        popModal();
        state.value = ModalState.OtherReason;
        break;
      case ModalState.AccountPin:
        popModal();
        state.value = ModalState.Account;
        break;
      case ModalState.AccountTopup:
        popModal();
        state.value = ModalState.Topup;
        break;
      case ModalState.ReaderAccount:
        popModal();
        cardSecret.value = "";
        state.value = ModalState.None;
        break;
      case ModalState.ReaderPin:
        selectedAccount.value = null;
        state.value = ModalState.ReaderAccount;
        popModal();

        break;
      default:
        const enumExhaustCheck: never = state.value;
        throw new Error(`Unhandled ModalState ${state.value}`);
    }
  }

  async function performRegisterCardRequest() {
    blockInput.value = true;
    if (
      selectedAccount.value === null ||
      selectedAccountPassword.value === null
    )
      throw new Error();
    const account: Account = selectedAccount.value;

    try {
      await checkoutApi.registerCard(
        cardSecret.value,
        selectedAccount.value.id,
        selectedAccountPassword.value
      );
      fireCheckmark(`Registered card for ${account.name}`);
      cardSecret.value = "";
      popModal();
      popModal();
      clear(false);
    } catch (e: unknown) {
      if (!isApiResponse(e)) throw e;
      console.error("Failed to confirm: ", e);
      switch (e.errorIdentifier) {
        case "invalid_account_pin":
          let pinModal = topModal.value as PinModalContext;
          pinModal.pinError = true;
          break;
        default:
          showError(e);
      }
    } finally {
      blockInput.value = false;
    }
  }

  function performCheckoutRequest(request: PurchaseApiRequest) {
    let checkoutPromise = confirmCheckout(request);

    blockInput.value = true;
    checkoutPromise.then(
      (result: ApiResponse<CheckoutResponse>) => {
        blockInput.value = false;

        let leftoverAccountBalance: number | null = null;
        if (result.result.balance_after !== undefined) {
          leftoverAccountBalance = result.result.balance_after;
        }

        let accountName = result.result.accountName;
        if (request.account) {
          accountName = request.account.name;
        }
        performCheckoutComplete(
          totalPrice.value,
          leftoverAccountBalance,
          request,
          result.result.transaction_id,
          accountName
        );
      },
      (e: ApiResponse<CheckoutResponse>) => {
        blockInput.value = false;

        console.error("Failed to confirm: ", e);
        switch (e.errorIdentifier) {
          case "insufficient_credits":
            // We should not clear the cardSecret on invalid pin and unknown
            // card secret, as we might need to retry with the same secret.
            cardSecret.value = "";
            showError(
              `${request.account?.name} has insufficient credits in account.`,
              "Insufficient credits"
            );
            break;

          case "invalid_account_pin":
            let pinModal = topModal.value as PinModalContext;
            pinModal.pinError = true;
            break;
          case "unknown_card_secret":
            state.value = ModalState.ReaderAccount;
            pushModal(
              new CheckoutAccountModalContext(
                accounts.value,
                totalPrice.value,
                "Unknown card, connect your account"
              )
            );
            break;
          default:
            cardSecret.value = "";
            showError(e);
            break;
        }
      }
    );
  }

  function performCheckoutComplete(
    cost: number,
    leftoverInAccount: number | null,
    request: PurchaseApiRequest,
    transactionId: number,
    accountName?: string
  ) {
    if (loginStore.hasCapability("PAYMENT_ACCOUNT_SELF") && leftoverInAccount) {
      accountSelfStore.setBalance(leftoverInAccount);
    }

    firePurchaseConfirmedCheckmark(cost, leftoverInAccount, accountName);

    if (loginStore.hasCapability("PAYMENT_UNDO")) {
      fireUndoToast(cost, request, transactionId, accountName);
    }

    // TODO This should be reset.
    clear();
    showCart.value = false;
    nonce.value = generateUuid();

    // Update account balances immediately
    if (loginStore.hasCapability("PAYMENT_ACCOUNT_OTHER")) {
      updateAccounts().then(
        () => {},
        (e) => console.error(e)
      );
    }
  }

  function fireUndoToast(
    cost: number,
    request: PurchaseApiRequest,
    transactionId: number,
    accountName?: string
  ) {
    let str = "";
    let method;
    switch (request.method) {
      case "account/self":
        method = `account (self)`;
        break;
      case "account/card":
        method = `account`;
        break;
      default:
        method = request.method;
    }

    if (request.upgradeAccount) {
      const upgradeAmount = request.upgradeAccountAmount
        ? request.upgradeAccountAmount
        : 0;
      const productPrice = cost - upgradeAmount;

      str =
        `Successful transaction for ${priceStr(
          cost
        )} (${method}). Bought: ${priceStr(productPrice)}` +
        `, upgraded: ${priceStr(upgradeAmount)}, account: '${
          request.upgradeAccount.name
        }'.`;
    } else if (accountName) {
      str = `Successful transaction for ${priceStr(
        cost
      )} on account '${accountName}'.`;
    } else {
      str = `Successful transaction for ${priceStr(cost)} with ${method}.`;
    }

    snackbarStore.doShow(str, "Undo", 7000, async () => {
      try {
        await transactionsApi.undoTransaction(transactionId);
        snackbarStore.doShow("Transaction undone.", "", 1200, null);

        // Set back the cart
        selectedTopupAccount.value = request.upgradeAccount;
        topupAmount.value = request.upgradeAccountAmount;
        cartEntries.value = request.products;

        if (loginStore.hasCapability("PAYMENT_ACCOUNT_SELF")) {
          const accountSelfStore = useAccountSelfStore();
          accountSelfStore.addBalance(cost);
        }
      } catch (e) {
        showError(e);
      }
    });
  }

  function firePurchaseConfirmedCheckmark(
    cost: number,
    leftoverInAccount: number | null,
    accountName?: string
  ) {
    if (leftoverInAccount !== null && accountName) {
      fireCheckmark(priceStr(leftoverInAccount) + " on " + accountName);
    } else if (leftoverInAccount !== null) {
      fireCheckmark("Balance: " + priceStr(leftoverInAccount));
    } else {
      fireCheckmark(priceStr(cost));
    }
  }

  function fireCheckmark(message: string) {
    confirmPurchaseCheckmarkText.value = message;
    setTimeout(() => {
      showConfirmPurchaseCheckmark.value = true;
      setTimeout(() => {
        showConfirmPurchaseCheckmark.value = false;
      }, 1300);
    }, 200);
  }

  function pushModal(modal: ModalContext) {
    modal.id = modalStackId.value++;
    modalStack.value.push(modal);
  }

  function popModal() {
    modalStack.value.pop();
  }

  const topModal = computed<ModalContext | null>(() =>
    modalStack.value.length
      ? modalStack.value[modalStack.value.length - 1]
      : null
  );

  async function updateProducts() {
    const response = await productsApi.products();
    categories.value = response.result.categories;
  }

  async function updateAccounts() {
    const response = await accountsApi.accounts();
    accounts.value = response.result.accounts;
  }

  async function updateReasons() {
    const response = await checkoutApi.reasons();
    reasons.value = response.result.reasons;
  }

  initialize();

  return {
    accounts,
    cardSecret,
    searchQuery,
    reasons,
    modalStack,
    showCart,
    cartEntries,
    topupAmount,
    totalPrice,
    showTopup,
    selectedTopupAccount,
    categories,
    totalCount,
    showConfirmPurchaseCheckmark,
    confirmPurchaseCheckmarkText,
    updateAccounts,
    purchaseWith,
    modalConfirmed,
    modalCancelled,
    addToCart,
    topupClicked,
    clearTopupClicked,
    modalRetry,
    firePurchaseConfirmedCheckmark,
  };
});
