import { defineStore } from "pinia";
import { accountsApi } from "../api/accounts";
import { Account } from "../types";

interface AccountSelfStore {
  balance: number;
  iban: string;
  creditAccount: boolean;
  sepaDate?: Date;
  sepaBalance?: number;
  account?: Account;
}
export const useAccountSelfStore = defineStore("accountSelfStore", {
  state: (): AccountSelfStore => {
    return {
      balance: 0,
      iban: "",
      creditAccount: false,
    };
  },
  actions: {
    async loadAccount() {
      const resp = await accountsApi.accountDetails();
      this.balance = resp.result.balance;
      this.creditAccount = resp.result.creditAccount;
      if (resp.result.sepa?.execDate && resp.result.sepa?.balance) {
        this.sepaBalance = resp.result.sepa.balance;
        this.sepaDate = new Date(resp.result.sepa.execDate);
      }
      if (resp.result.sepa?.iban) {
        this.iban = resp.result.sepa.iban;
      }
      this.account = resp.result.account;
      return this as AccountSelfStore;
    },
    setBalance(balance: number) {
      this.balance = balance;
    },
    addBalance(cost: number) {
      this.balance += cost;
    },
  },
});
