import {
  Api,
  ApiResponse,
  objectToSearchParamString,
  urlWithSearchParams,
} from "./api";
import { Category } from "../types";
import { NewProductData, Product, ProductData } from "../admin_types";
import { PaginatedResponse } from "./pagination";

export class ProductsResponse {
  categories: Category[];
}

export class AdminProductsResponse {
  categories: {
    data: Omit<Category, "products">[];
  };
  products: {
    data: Product[];
  };
}

export interface AdminProductsRequest {
  page?: number;
  perPage?: number;
  search?: string;
  sort?: string;
  priceFilter?: string;
  quantityFilter?: string;
  categoryFilter?: string;
  ledgerFilter?: string;
  slideFilter?: boolean;
  deletedFilter?: boolean;
  idFilter?: string;
}

export interface TopBuyersItem {
  accountName: string;
  count: number;
}

export interface ProductPurchasePerDayItem {
  date: string; // format: "2004-MM-DD"
  purchases: number;
  cumPurchases: number;
  comparisonPurchases: number;
  comparisonCumPurchases: number;
}

export interface ProductCountPerDayResponse {
  year: number;
  comparisonYear: number;
  purchases: ProductPurchasePerDayItem[];
}

export class ProductsApi extends Api {
  public async products(): Promise<ApiResponse<ProductsResponse>> {
    return this.get<ProductsResponse>("/api/products/");
  }

  /**
   * @deprecated Do not use this, but paginated /api/admin/products/.
   */
  public async adminProducts(): Promise<ApiResponse<AdminProductsResponse>> {
    return this.get<AdminProductsResponse>("/api/admin/products/all/");
  }

  async adminPaginatedProducts(
    params: AdminProductsRequest
  ): Promise<ApiResponse<PaginatedResponse<Product>>> {
    let url = "/api/admin/products/";
    const queryString = objectToSearchParamString({
      page: 1,
      perPage: 15,
      ...params,
    });

    if (queryString) {
      url += "?" + queryString;
    }

    return this.get<PaginatedResponse<Product>>(url);
  }

  public async postProduct(product: NewProductData): Promise<ProductData> {
    if ("id" in product) {
      // copy
      product = { ...product };
      delete product["id"];
    }

    return Api.rawRequest("POST", `/api/admin/products/`, product);
  }

  public async putProduct(product: ProductData): Promise<ProductData> {
    return Api.rawRequest("PUT", `/api/admin/products/${product.id}/`, product);
  }

  public async deleteProduct(productId: number): Promise<void> {
    return Api.rawRequest("DELETE", `/api/admin/products/${productId}/`);
  }

  public async topBuyers(
    productId: number,
    startDate?: string
  ): Promise<TopBuyersItem[]> {
    const url = `/api/admin/products/${productId}/count/`;
    return Api.rawRequest(
      "GET",
      urlWithSearchParams(url, { start: startDate })
    );
  }

  public async countPerDayOfYear(
    productId: number,
    year?: number
  ): Promise<ProductCountPerDayResponse> {
    const url = `/api/admin/products/${productId}/count_per_day/`;
    return Api.rawRequest("GET", urlWithSearchParams(url, { year }));
  }
}

export const productsApi = new ProductsApi();
