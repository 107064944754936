import { createApp } from "vue";
import { useLoginStore } from "../store/login";
import * as Sentry from "@sentry/vue";
import { router } from "../router";
import { createPinia } from "pinia";
import { VueQueryPlugin } from "@tanstack/vue-query";
import { BrowserTracing } from "@sentry/tracing";

const app = createApp({});
app.use(router);
app.use(createPinia());
app.use(VueQueryPlugin);
app.config.globalProperties.$form = () => {
  throw Error();
};

declare var __SENTRY_DSN__: string;
declare var __APP_VERSION__: string;

Sentry.init({
  Vue: app,
  dsn: __SENTRY_DSN__,
  release: __APP_VERSION__,
  logErrors: true,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["pos.svia.nl", "develop.pos.svia.nl"],
    }),
  ],
  tracesSampleRate: 1.0,
});

const loginStore = useLoginStore();
let initializePromise = loginStore.initialize(router);
if (initializePromise != null) {
  initializePromise.then(
    () => {
      app.mount("main");
    },
    (error) => {
      alert("Error getting config " + error);
    }
  );
}
