<template lang="pug">
div
  //- Stack of modal dialogs.
  transition(name="modal-stack-animation")
    .modal-stack(v-show="store.modalStack.length > 0")
      transition-group.modal-animation-container(
        name="modal-animation",
        tag="div"
      )
        //- Loop through the stack:
        .modal-container(
          v-for="(item, index) in store.modalStack",
          :style="{ 'z-index': 200 + index }",
          :key="item.id"
        )
          //- The different types of modals:
          //- Generic message modal
          message-modal(
            v-if="item.modalView === 'message' || item.modalView === 'error'",
            :errorModal="item.modalView === 'error'",
            :title="item.title",
            :message="item.message",
            @cancel="cancelModal(item, ...arguments)",
            @confirm="confirmModal(item, ...arguments)"
          )

          // Generic input modal
          prompt-modal(
            v-if="item.modalView === 'prompt'",
            :title="item.title",
            :message="item.message",
            @cancel="cancelModal(item, ...arguments)",
            @confirm="confirmModal(item, ...arguments)"
          )
            // Conditions modal
          conditions-modal(
            v-if="item.modalView === 'condition'",
            @cancel="cancelModal(item, ...arguments)",
            @confirm="confirmModal(item, ...arguments)"
          )
</template>

<script setup lang="ts">
import MessageModal from "./message_modal.vue";
import PromptModal from "./prompt_modal.vue";
import ConditionsModal from "../ConditionsModal.vue";
import { useModalStore } from "../../scripts/store/modal";

const store = useModalStore();

function confirmModal(item: any) {
  store.modalConfirmed(item, arguments);
}

function cancelModal(item: any) {
  store.modalCancelled(item, arguments);
}
</script>

<style lang="sass">
@import "../../styles/common"
@import "../../styles/modal"
</style>
