import { Account, CartEntry } from "../../types";
import { ApiResponse } from "../../api/api";
import {
  checkoutApi,
  CheckoutCardResponse,
  CheckoutRequest,
  CheckoutResponse,
} from "../../api/checkout";

export class PurchaseApiRequest {
  method: string;
  nonce: string;
  products: CartEntry[];

  account: Account | null = null;
  accountPassword: string | null = null;

  cardSecret: string | null = null;

  upgradeAccount: Account | null = null;
  upgradeAccountAmount: number | null = null;

  checkoutReason: string | null = null;

  constructor(method: string, nonce: string, products: CartEntry[]) {
    this.method = method;
    this.nonce = nonce;
    this.products = products;
  }
}

function purchaseRequestToCheckoutRequest(
  request: PurchaseApiRequest
): CheckoutRequest {
  const accountId = request.account ? request.account.id : null;
  const upgradeAccountId = request.upgradeAccount
    ? request.upgradeAccount.id
    : null;

  return {
    products: request.products.map((entry: CartEntry) => ({
      id: entry.product.id,
      amount: entry.count,
    })),
    account_id: accountId,
    pin: request.accountPassword,
    upgrade_account_id: upgradeAccountId,
    upgrade_amount: request.upgradeAccountAmount,
    reason: request.checkoutReason,
    nonce: request.nonce,
    cardSecret: request.cardSecret,
  };
}

export async function cardCheckoutStart(
  request: PurchaseApiRequest
): Promise<ApiResponse<CheckoutCardResponse>> {
  const data = purchaseRequestToCheckoutRequest(request);
  return await checkoutApi.checkoutCard(data);
}

export async function confirmCheckout(
  request: PurchaseApiRequest
): Promise<ApiResponse<CheckoutResponse>> {
  const data = purchaseRequestToCheckoutRequest(request);
  return await checkoutApi.checkout(request.method, data);
}
