<template lang="pug">
.modal(@click="emit('cancel')")
  .inner(@click.stop)
    .title {{ title }}

    .message-container(:class="{ 'error-modal': errorModal }")
      .message-left
        img(src="/img/alert-circle.svg")
      .message-right
        | {{ message }}

    .buttons
      input.full-width(
        @click="emit('confirm')",
        type="button",
        v-model="confirmText"
      )
</template>

<script setup lang="ts">
import { useTimer } from "../../scripts/composables/modal";
import { computed, onUnmounted } from "vue";

interface MessageModalProps {
  title: string;
  message: string;
  errorModal: boolean;
}
interface PriceModalEmits {
  (e: "confirm"): void;
  (e: "cancel"): void;
}

const emit = defineEmits<PriceModalEmits>();

const props = defineProps<MessageModalProps>();
const { secondsLeft, stop } = useTimer(() => emit("confirm"), 10);
const confirmText = computed(() => `OK (${secondsLeft.value})`);
onUnmounted(stop);
</script>

<style lang="sass" scoped>
@import "../../styles/vars"
@import "../../styles/modal"

.modal
    .inner
        width: $modal-width

        @include screen-compact
            width: $modal-width-compact

    .message-container
        flex: 1

        display: flex
        flex-flow: row

        padding: $modal-message-padding

        @include screen-compact
            padding: $modal-message-padding-compact

        .message-left
            display: none

            padding: 0 1em 0 0

            img
                width: 3em
                height: 3em

        .message-right
            flex: 1
            text-align: center

            font-family: $font-caption2
            font-weight: 300
            font-size: $modal-message-size

            @include screen-compact
                font-size: $modal-message-size-compact

        &.error-modal
            padding: 1.5em 1em 2em 1em

            .message-left
                display: block

            .message-right
                text-align: left
</style>
