<template lang="pug">
.search-container
  .search
    .search__icon
    input(
      :id="id",
      type="search",
      :placeholder="placeholder",
      :value="value",
      @input="onInput",
      @focus="onFocus"
    )
  .search__clear(@click="clear()")
    | Cancel
</template>

<script setup lang="ts">
import {
  useVirtualKeyboard,
  useVirtualKeyboardStore,
} from "../../scripts/composables/form";
import { watch } from "vue";

const props = withDefaults(
  defineProps<{
    modelValue: string;
    placeholder?: string;
  }>(),
  {
    placeholder: "Search",
  }
);

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

const virtualKeyboardStore = useVirtualKeyboardStore();
const { id, value, onInput, onFocus, clear } = useVirtualKeyboard(
  props.modelValue
);

// We watch for external store changes, because when a transaction is completed, the store.searchQuery is modified.
// This needs to be propagated through to the virtualKeyboardStore.
watch(
  () => props.modelValue,
  () => {
    virtualKeyboardStore.inputs[id] = props.modelValue;
  }
);

virtualKeyboardStore.$subscribe((mutation, state) => {
  emit("update:modelValue", state.inputs[id]);
});
</script>

<style lang="sass" scoped>
@import "../../styles/search.sass"
</style>
