<template lang="pug">
div
  p
    | This is the Point of Sale service from Vereniging Informatiewetenschappen Amsterdam (V.I.A.) hereinafter referred to as <b>via</b>. We use this service to sell our products at our association room and bar

  p
    | We offer accounts (tabs) for our members. These accounts can be credited through iDeal, at the bar during drinks, or automatically credited with SEPA Direct Debit. You can request an account at "Account setup" in the navigation bar if you like. Approval is done manually by a board member.

  ul
    li
      | While studying at the University of Amsterdam it is not possible to get a refund for the funds on your account.
      | If you're done studying it is possible to get a refund for the funds and close your account. Contact the board of <b>via</b> to arrange the refund.

    li
      | <b>via</b> reserves the right to close your account if it is not used for more than 1 year. Any positive funds on this account will then expire.
      | <b>via</b> will try their best to contact you if your account is close to expiring.
      | <b>via</b> takes extreme care to prevent any funds left in this account from expiring, they cannot however guarantee this if they cannot contact you.

    li
      | It is possible to turn your account into an credit account (dutch: incassorekening), allowing your balance to be negative.
      | By enabling this feature you will agree to a payment obligation to <b>via</b>. <b>via</b> will credit your bank account monthly for any outstanding amount.
      | If, for any reason, this direct debit fails, this feature is turned off, and you're obligated to credit your account manually within a month.

    li
      | If, for any reason, <b>via</b> is dissolved, all funds on all accounts will be liquidated as meant in article 17 of the statutes of <b>via</b>.

    li
      | Except as represented in this agreement, this service is provided ​“AS IS”. Other than as provided in this agreement, <b>via</b> makes no other warranties, express or implied, and hereby disclaims all implied warranties, including any warranty of merchantability and warranty of fitness for a particular purpose.

    li
      | <b>Via</b> tries it best to prevent data loss of the service, such as the use of backups and other security measures. <b>via</b> can however not guarantee this, and will not be responsible for any loss of funds.

    li
      | By refusing the terms and conditions, you won't be able to use the Point of Sale service and will be logged out automatically

    li
      | <b>Via</b> is allowed to supplement, adjust or replace the terms and conditions. <b>via</b> will notify the account owner 14 days before the starting date of the supplement, adjustment or replacement. If you don’t agree with the supplement, adjustment or replacement of the terms and conditions you can submit an objection within 14 days.

  p
    | Deviation from the Terms and Conditions is only possible by written provision.
    | In all cases of doubt about all possible circumstances the board decides.
</template>

<style lang="sass" scoped>
div
    margin: 10px

li
    margin-bottom: 10px
</style>

<script setup lang="ts"></script>
