<template lang="pug">
.products-container
  searchbar(v-model="store.searchQuery")
  .products
    .category(v-if="store.searchQuery.length > 0")
      .category__name Results
      .category__products
        .product(
          v-for="product in filteredProducts",
          @click="addProduct(product)",
          :data-color="productColors[product.id]",
          :data-id="product.id",
          :data-price="product.price"
        )
          .product__name {{ product.name }}
          .product__price {{ priceStr(product.price) }}

    template(v-for="category in categories")
      .category(v-if="store.searchQuery.length === 0")
        .category__name {{ category.name }}
        .category__products
          .product(
            v-for="product in category.products",
            :class="productClasses(product)",
            @click="addProduct(product)",
            :data-color="category.color",
            :data-id="product.id",
            :data-price="product.price"
          )
            .product__name {{ product.name }}
            .product__price {{ priceStr(product.price) }}

  transition(name="snackbar-animation")
    Snackbar.product-snackbar(
      v-show="snackbarStore.show",
      :text="snackbarStore.text",
      :actionText="snackbarStore.actionText",
      @action="snackbarStore.doAction"
    )
</template>

<script setup lang="ts">
import { Category } from "../../scripts/types";
import type { Product } from "../../scripts/types";
import { usePurchaseStore } from "../../scripts/store/purchase";
import { useSnackbarStore } from "../../scripts/store/snackbar";
import Snackbar from "../snackbar.vue";
import Searchbar from "../common/searchbar.vue";
import { priceStr } from "../../scripts/util";
import Fuse from "fuse.js";
import { reactive, computed } from "vue";

interface ProductsProps {
  categories: Category[];
}
interface ProductsEmits {
  (e: "add", product: Product): void;
}
const props = defineProps<ProductsProps>();
const emit = defineEmits<ProductsEmits>();

const store = usePurchaseStore();

const snackbarStore = useSnackbarStore();
const productColors = reactive<{ [productId: number]: number }>({});

function productClasses(product: Product): object {
  return {
    "long-name": product.name.length > 36,
  };
}

const filteredProducts = computed(() => {
  if (store.searchQuery.length == 0) {
    return [];
  }

  let products: Product[] = [];
  let productsIdsPresent = new Set();
  props.categories.forEach((category) => {
    category.products.forEach((product) => {
      if (!productsIdsPresent.has(product.id)) {
        productColors[product.id] = category.color;
        products.push(product);
        productsIdsPresent.add(product.id);
      }
    });
  });

  const fuse = new Fuse(products, {
    keys: ["name"],
    shouldSort: true,
    threshold: 0.5,
    includeScore: false,
    includeMatches: false,
  });
  return fuse.search(store.searchQuery);
});

function addProduct(product) {
  emit("add", product);
}
</script>

<style lang="sass" scoped>
@import "../../styles/colors"
@import "../../styles/mixins"
@import "../../styles/vars"

.search-container
    background: white

.product-snackbar
    position: absolute
    bottom: 0
    left: 1em
    right: 1em

@media (max-width: 795px)
    .product-snackbar
        left: 0
        right: 0

.snackbar-animation-enter-active, .snackbar-animation-leave-active
    transition: all .3s ease

.snackbar-animation-enter-from, .snackbar-animation-leave-to
    opacity: 0
    transform: translateY(30px)

.snackbar-animation-enter-to, .snackbar-animation-leave-from
    opacity: 1

.products-container
    @media (max-width: 1165px)
        padding-top: 3.5rem

    @media (max-width: 795px)
        padding-top: 3.5rem

    position: relative
    display: flex
    flex: auto
    flex-direction: column

    @include mix-user-select(none)

.products
    @media (max-width: 795px)
        padding: 0 8px 8px

    padding: 0 16px 16px
    flex: 1 1 auto
    overflow-y: auto

.category__name
    font-size: 1.7em
    text-align: center
    margin-top: 24px
    margin-bottom: 8px

.category:first-child .category__name
    margin-top: 12px

.category__products
    display: grid
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr))
    grid-gap: 10px

.product__name
    position: absolute
    left: 0
    right: 0
    top: 0
    bottom: 30px

    display: flex
    text-align: center
    justify-content: center
    align-items: center

    font-size: 1.2em
    hyphens: auto
    padding: 5px

.product:active
    background: #e0e0e0

.product__price
    position: absolute
    left: 0
    right: 0
    bottom: 0
    height: 30px

    border-bottom-left-radius: 4px
    border-bottom-right-radius: 4px

    display: flex
    text-align: center
    justify-content: center
    align-items: center
    color: #ffffff

.product
    width: 100%
    height: 110px
    box-sizing: border-box

    background-color: #ffffff
    border: 1px solid #e0e0e0
    border-radius: 5px

    display: inline-block
    position: relative

    cursor: pointer
    overflow: hidden
    hyphens: auto
    white-space: pre-line

    user-select: none

    &.long-name
        .product__name
            word-break: break-word
            font-size: 13px

@each $key, $color in $product-colors
    .product[data-color="#{$key}"] .product__price
        background-color: $color
</style>
