<template lang="pug">
.container
  .app
    .navbar
      .icon.icon--menu(@click="showSidebar = true")
      .navbar--center
        img(src="/img/via_logo.svg")
      .icon.icon--virtual-keyboard
      .icon.icon--cart(@click="purchaseStore.showCart = true")
        .icon--cart__badge(v-show="purchaseStore.totalCount > 0") {{ purchaseStore.totalCount }}

    sidebar(
      v-bind:class="{ show: showSidebar }",
      @cancel="showSidebar = false",
      @logout="logout",
      active-page="index"
    )

    products(:categories="purchaseStore.categories", @add="addToCart")

    cart(
      :show="purchaseStore.showCart",
      :cart-entries="purchaseStore.cartEntries",
      :topupAmount="purchaseStore.topupAmount",
      :price="purchaseStore.totalPrice",
      @checkout="cartCheckout",
      @topup="cartTopup",
      @cancel="purchaseStore.showCart = false",
      @clearTopup="cartClearTopup",
      :showTopup="purchaseStore.showTopup",
      :topupAccount="purchaseStore.selectedTopupAccount",
      :categories="purchaseStore.categories"
    )

  purchase-overlay

  modal-overlay

  virtual-keyboard
</template>

<script setup lang="ts">
import Cart from "./cart.vue";
import Products from "./products.vue";
import PurchaseOverlay from "./purchase_overlay.vue";
import Sidebar from "../sidebar.vue";
import VirtualKeyboard from "../VirtualKeyboard.vue";

import { usePurchaseStore } from "../../scripts/store/purchase";
import type { Product } from "../../scripts/types";
import ModalOverlay from "./modal_overlay.vue";
import { ref } from "@vue/reactivity";

const purchaseStore = usePurchaseStore();

const showSidebar = ref(false);

function logout() {
  localStorage.removeItem("access_token");
  location.href = "/login";
}

function cartCheckout(type: string) {
  purchaseStore.purchaseWith(type);
}

function addToCart(product: Product) {
  purchaseStore.addToCart(product);
}

function cartTopup() {
  purchaseStore.topupClicked();
}

function cartClearTopup() {
  purchaseStore.clearTopupClicked();
}
</script>

<style lang="sass">
@import "../../styles/common"
</style>
