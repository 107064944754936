<template>
  <transition name="keyboard-animation">
    <div
      ref="keyboardWrapper"
      class="virtual-keyboard-element"
      v-show="virtualKeyboardStore.shown"
    ></div>
  </transition>
</template>

<script setup lang="ts">
import Keyboard from "simple-keyboard";

import { onMounted, onUnmounted } from "vue";
import { useVirtualKeyboardStore } from "../scripts/composables/form";
import { ref } from "@vue/reactivity";
import { onClickOutside } from "@vueuse/core";

const virtualKeyboardStore = useVirtualKeyboardStore();
let keyboard = ref<null | Keyboard>(null);
let keyboardWrapper = ref(null);

onMounted(() => {
  keyboard.value = new Keyboard("virtual-keyboard-element", {
    theme: "hg-theme-default hg-theme-ios",
    layout: {
      default: [
        "q w e r t y u i o p {bksp}",
        "a s d f g h j k l {enter}",
        "{shift} z x c v b n m , . {shift}",
        "{alt} {space} {alt} {downkeyboard}",
      ],
      shift: [
        "Q W E R T Y U I O P {bksp}",
        "A S D F G H J K L {enter}",
        "{shiftactivated} Z X C V B N M , . {shiftactivated}",
        "{alt} {space} {alt} {downkeyboard}",
      ],
      alt: [
        "1 2 3 4 5 6 7 8 9 0 {bksp}",
        `@ # $ & * ( ) ' " {enter}`,
        "{shift} % - + = / ; : ! ? {shift}",
        "{default} {space} {default} {downkeyboard}",
      ],
    },
    display: {
      "{alt}": ".?123",
      "{shift}": " ",
      "{shiftactivated}": " ",
      "{enter}": " ",
      "{bksp}": " ",
      "{downkeyboard}": " ",
      "{space}": " ",
      "{default}": "ABC",
    },
    onChange: (e) => onChange(e),
    onKeyPress: (k) => onKeyPress(k),
  });
  onClickOutside(keyboardWrapper, (event: PointerEvent) => {
    if (!(event.composedPath()[0] instanceof HTMLInputElement)) {
      virtualKeyboardStore.shown = false;
    }
  });
});
onUnmounted(() => keyboard.value?.destroy());

function onChange(input: string) {
  // The keyboard emits the fully current string, instead of the pressed
  // character.
  virtualKeyboardStore.inputs[virtualKeyboardStore.inputName] = input;
}

virtualKeyboardStore.$subscribe((mutation, state) => {
  keyboard.value?.setOptions({ inputName: virtualKeyboardStore.inputName });
  // Internally the keyboard keeps track of the current contents of the input.
  // When the store is updated, with synchronise the value to the keyboard,
  // such that the correct value is emitted with Keyboard.onChange.
  keyboard.value?.setInput(
    virtualKeyboardStore.inputs[virtualKeyboardStore.inputName]
  );
});

function onKeyPress(key) {
  if (key === "{shift}") {
    keyboard.value?.setOptions({ layoutName: "shift" });
  } else if (key == "{shiftactivated}" || key == "{default}") {
    keyboard.value?.setOptions({ layoutName: "default" });
  } else if (key == "{alt}") {
    keyboard.value?.setOptions({ layoutName: "alt" });
  }

  if (key === "{downkeyboard}") {
    virtualKeyboardStore.shown = false;
  }
}
</script>

<style lang="sass">
@import "simple-keyboard/build/css/index.css"
@import "../styles/_mixins.sass"

.virtual-keyboard-element
  position: fixed
  z-index: 2000
  bottom: 0

  box-shadow: 0 30px 80px rgba(0, 0, 0, 0.5)

.virtual-keyboard-element.hg-theme-ios
  margin: auto

  &.hg-theme-default .hg-row .hg-button
    flex-grow: 1
    cursor: pointer
    max-width: initial

  .hg-row
    display: flex

    &:not(:last-child)
      margin-bottom: 12px

    .hg-button:not(:last-child)
      margin-right: 12px

    &:nth-child(2)
      margin-left: 40px

  &.hg-theme-default
    padding: 12px
    border-radius: 5px

    background-color: #d1d4d9

    .hg-button
      border-radius: 5px
      box-sizing: border-box
      background: white
      border-bottom: 1px solid #898a8c
      cursor: pointer
      display: flex
      align-items: center
      justify-content: center
      box-shadow: none
      max-width: 60px
      min-width: 60px
      height: 60px
      min-height: 75px
      font-size: 32px
      font-family: "Helvetica Neue", sans-serif
      font-weight: 300

      @include mix-user-select(none)
      -webkit-touch-callout: none
      touch-action: none

      &.hg-functionBtn
        background-color: #A9AFBA

      &.hg-button-space, &.hg-button-shiftactivated
        background-color: #ffffff

      &:active, &:focus
        background: #A9AFBA

    .hg-button-space
      max-width: 448px
      min-width: 448px

    .hg-button-enter
      max-width: 110px
      min-width: 110px

    .hg-button-alt, .hg-button-default
      width: 60px

    .hg-button-bksp
      background-size: 28.17px 24px
      background-repeat: no-repeat
      background-position: 50% 50%
      background-image: url('/img/virtual-keyboard-backspace.png')

    .hg-button-enter
      background-size: 26.05px 24px
      background-repeat: no-repeat
      background-position: 50% 50%
      background-image: url('/img/virtual-keyboard-enter.png')

    .hg-button-shift
      background-size: 28px 24px
      background-repeat: no-repeat
      background-position: 50% 50%
      background-image: url('/img/virtual-keyboard-shift.png')

    .hg-button-shiftactivated
      background-size: 28px 24px
      background-repeat: no-repeat
      background-position: 50% 50%
      background-image: url('/img/virtual-keyboard-shiftactivated.png')

    .hg-button-downkeyboard
      background-size: 31.18px 32px
      background-repeat: no-repeat
      background-position: 50% 50%
      background-image: url('/img/virtual-keyboard-downkeyboard.png')

      margin-right: 95px

.keyboard-animation-enter-active
  transition: transform .3s cubic-bezier(.06,.64,.47,1)

.keyboard-animation-leave-active
  transition: transform .4s cubic-bezier(.73,0,.25,1)

.keyboard-animation-enter-from
  transform: translateY(100%)

.keyboard-animation-enter-to
  transform: translateY(0)

.keyboard-animation-leave-from
  transform: translateY(0)

.keyboard-animation-leave-to
  transform: translateY(100%)
</style>
