<template lang="pug">
.bar(@click="retry", :style="colorMapping")
  div
    a-spin(
      v-if="pendingTransactionState.state === 'loading' || (pendingTransactionState.state === 'loaded' && pendingTransactionState.transactionStatus === TransactionStatus.PENDING)"
    )
    .icons
      div(:class="classMapping")

  .vertical
    b {{ priceString }}

    i(v-if="!paymentFailed")
      | {{ cardTransactionStore.terminal?.friendly_name }}
    i(v-else)
      | {{ retryText }}
</template>

<script lang="ts" setup>
import { watch } from "vue";
import {
  useCardTransactionStore,
  usePendingTransaction,
} from "./CardTransactionBase";
import { usePurchaseStore } from "../../scripts/store/purchase";
import { priceStr, showError } from "../../scripts/util";
import { computed } from "@vue/reactivity";
import { TransactionStatus } from "../../scripts/types";
import { storeToRefs } from "pinia";

const cardTransactionStore = useCardTransactionStore();
const { pendingTransactionId } = storeToRefs(cardTransactionStore);
const purchaseStore = usePurchaseStore();
const pendingTransactionState = usePendingTransaction(pendingTransactionId);

const priceString = computed(() => priceStr(cardTransactionStore.price!));
const paymentFailed = computed(
  () =>
    pendingTransactionState.value.state === "error" ||
    (pendingTransactionState.value.state === "loaded" &&
      pendingTransactionState.value.failed)
);

const finished = computed(
  () =>
    pendingTransactionState.value.state === "loaded" &&
    pendingTransactionState.value.transactionStatus ===
      TransactionStatus.COMPLETED
);

watch(finished, (finished) => {
  if (finished) {
    purchaseStore.firePurchaseConfirmedCheckmark(
      cardTransactionStore.price!,
      null
    );
    cardTransactionStore.reset();
  }
});

watch([paymentFailed], () => {
  if (paymentFailed.value) {
    showError("Transaction failed, please try again!");
  }
});

const retryTestMap = {
  [TransactionStatus.EXPIRED]: "Payment has expired",
  [TransactionStatus.CANCELLED]: "Payment has been cancelled",
  [TransactionStatus.DELETED]: "Payment has been deleted",
} as const;

const retryText = computed(() => {
  if (pendingTransactionState.value.state === "error") {
    return pendingTransactionState.value.error;
  }
  if (pendingTransactionState.value.state === "loaded") {
    return (
      retryTestMap[pendingTransactionState.value.transactionStatus || ""] ||
      "Something went wrong"
    );
  }
  return "Something went wrong";
});

const classMapping = computed(() => {
  return {
    cancelled: paymentFailed.value,
    completed: finished.value,
  };
});

const colorMapping = computed(() => {
  // TODO: Dit zijn de Ant tag kleuren, hoe doen we dit normaal. Kan niet SCSS variables includen volgens mij
  let color = "#e6f7ff";
  let borderColor = "#91d5ff";

  if (finished.value) {
    color = "#f6ffed";
    borderColor = "#b7eb8f";
  } else if (paymentFailed.value) {
    color = "#fff1f0";
    borderColor = "#ffa39e";
  }

  return {
    "background-color": color,
    "border-color": borderColor,
  };
});

async function retry() {
  if (paymentFailed.value && cardTransactionStore.originalRequest) {
    const {
      products,
      upgradeAccount,
      upgradeAccountAmount,
    } = cardTransactionStore.originalRequest;
    purchaseStore.cartEntries = products;
    purchaseStore.selectedTopupAccount = upgradeAccount;
    purchaseStore.topupAmount = upgradeAccountAmount;

    cardTransactionStore.reset();
  }
}
</script>

<style lang="sass" scoped>
@import "../../styles/_colors.sass"

.bar
  color: black

  font-size: 1em
  border-radius: 2px
  //border: 1px solid
  height: 75px

  display: flex

  justify-content: center
  align-items: center

  cursor: pointer

  &:active
    background-color: #e0e0e0

  div
    width: 40%
    display: flex
    justify-content: center
    align-items: center

  .vertical
    flex-direction: column
    width: 60%

  .icons
    & > div
      width: 100%
      height: 75px
      background-size: contain
      background-repeat: no-repeat
      background-position: center

    .cancelled
      height: 50px

      background-color: #d00202
      mask-image: url("/img/add_to_cart.svg")
      mask-size: contain
      mask-repeat: no-repeat
      mask-position: center

    .completed
      height: 50px
      background-image: url("/img/check.svg")
</style>
