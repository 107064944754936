import { Api, ApiResponse } from "./api";
import { CheckoutReason } from "../types";
import { CardTerminal } from "./registers";

export class CheckoutRequest {
  products: { id: number; amount: number }[];
  account_id: number | null;
  pin: string | null;
  upgrade_account_id: number | null;
  upgrade_amount: number | null;
  nonce: string;
  reason: string | null;
  cardSecret: string | null;
}

export class CheckoutResponse {
  transaction_id: number;
  balance_before: number | undefined;
  balance_after: number | undefined;
  balance: number | undefined;
  accountName?: string;
}

export class CheckoutCardResponse {
  transaction_id: number;
  terminal?: CardTerminal;
}

export interface CheckoutReasonResponse {
  reasons: CheckoutReason[];
}

export class CheckoutApi extends Api {
  public async checkout(
    method: string,
    data: CheckoutRequest
  ): Promise<ApiResponse<CheckoutResponse>> {
    return this.post<CheckoutResponse>("/api/checkout/" + method + "/", data);
  }

  public async checkoutCard(
    data: CheckoutRequest
  ): Promise<ApiResponse<CheckoutCardResponse>> {
    return this.post<CheckoutCardResponse>("/api/checkout/card/", data);
  }

  public async reasons(): Promise<ApiResponse<CheckoutReasonResponse>> {
    return this.get<CheckoutReasonResponse>("/api/checkout/reasons/");
  }

  registerCard(cardSecret: string, accountId: number, pin: string) {
    return this.post("/api/reader/register/", {
      cardSecret: cardSecret,
      accountId: accountId,
      pin: pin,
    });
  }
}

export const checkoutApi = new CheckoutApi();
