import { createRouter, createWebHistory } from "vue-router";
import App from "../components/purchase/purchase.vue";

const Login = () => import("../components/login.vue");
const LoginCallback = () => import("../components/login_callback.vue");
const Admin = () => import("../components/admin/admin.vue");
const Setup = () => import("../components/setup.vue");
const About = () => import("../components/about.vue");
const UserDetailPage = () => import("../pages/admin/UserDetailPage.vue");
const AccountDetailPage = () => import("../pages/admin/AccountDetailPage.vue");
const AccountSelfPage = () => import("../pages/admin/AccountSelfPage.vue");
const AccountOverviewPage = () =>
  import("../pages/admin/AccountOverviewPage.vue");
const ProductOverviewPage = () =>
  import("../pages/admin/ProductOverviewPage.vue");
const RegisterOverviewPage = () =>
  import("../pages/admin/RegisterOverviewPage.vue");
const BarPage = () => import("../pages/admin/BarPage.vue");
const CategoryOverviewPage = () =>
  import("../pages/admin/CategoryOverviewPage.vue");
const AccountRequestPage = () =>
  import("../pages/admin/AccountRequestPage.vue");
const TransactionOverviewPage = () =>
  import("../pages/admin/TransactionOverviewPage.vue");
const IdealOverviewPage = () => import("../pages/admin/IdealOverviewPage.vue");
const DirectDebitPage = () => import("../pages/admin/DirectDebitPage.vue");
const DirectDebitBatchDetailsPage = () =>
  import("../components/admin/DirectDebitBatchDetailsPage.vue");
const UserOverviewPage = () => import("../pages/admin/UserOverviewPage.vue");
const AdminRegisterDetails = () =>
  import("../components/admin/register_details.vue");
const AdminAuditLogs = () => import("../components/admin/audit.vue");
const SettingsPage = () => import("../pages/admin/SettingsPage.vue");
const ExactOnlinePage = () => import("../pages/admin/ExactOnlinePage.vue");
const AdminExactOnlineSetup = () =>
  import("../components/admin/exact_online_setup.vue");
const AdminInvoices = () => import("../components/admin/invoices.vue");
const BarMenu = () => import("../components/slides/BarMenu.vue");

const routes = [
  {
    path: "/",
    redirect: "/pos",
  },
  {
    component: App,
    name: "pos",
    path: "/pos",
  },
  {
    component: Login,
    path: "/login",
  },
  {
    component: LoginCallback,
    path: "/login/callback",
  },
  {
    children: [
      {
        component: AccountSelfPage,
        path: "/account",
      },
      {
        component: About,
        name: "about",
        path: "/about",
      },
    ],
    component: Admin,
    path: "/",
  },
  {
    children: [
      {
        component: Setup,
        name: "accountSetup",
        path: "",
      },
    ],
    component: Admin,
    path: "/setup",
  },
  {
    children: [
      {
        component: CategoryOverviewPage,
        name: "adminCategories",
        path: "categories",
      },
      {
        component: ProductOverviewPage,
        name: "adminProducts",
        path: "products",
      },
      {
        component: RegisterOverviewPage,
        name: "adminRegisters",
        path: "registers",
      },
      {
        component: BarPage,
        name: "adminBar",
        path: "bar",
      },
      {
        component: AdminRegisterDetails,
        name: "adminRegisterDetails",
        path: "registers/:registerId",
      },
      {
        component: AccountOverviewPage,
        name: "adminAccounts",
        path: "accounts",
      },
      {
        component: AccountDetailPage,
        name: "adminAccountDetails",
        path: "accounts/:accountId",
      },
      {
        component: AccountRequestPage,
        name: "adminAccountRequests",
        path: "account_requests",
      },
      {
        component: AdminInvoices,
        name: "adminInvoices",
        path: "invoices",
      },
      {
        component: UserOverviewPage,
        name: "adminUsers",
        path: "users",
      },
      {
        component: UserDetailPage,
        name: "adminUserDetails",
        path: "users/:userId",
      },
      {
        component: TransactionOverviewPage,
        name: "adminTransactions",
        path: "transactions",
      },
      {
        component: DirectDebitPage,
        name: "adminDirectDebit",
        path: "direct_debit",
      },
      {
        component: DirectDebitBatchDetailsPage,
        name: "adminDirectDebitDetails",
        path: "direct_debit/:batchId",
      },
      {
        component: AdminAuditLogs,
        name: "adminAuditLogs",
        path: "audit_logs",
      },
      {
        component: IdealOverviewPage,
        name: "adminPaymentsIdeal",
        path: "ideal",
      },
      {
        component: SettingsPage,
        name: "adminSettings",
        path: "settings",
      },
      {
        component: AdminExactOnlineSetup,
        name: "adminExactOnlineSetup",
        path: "exact/setup",
        alias: "exact/oauth/success",
      },
      {
        component: ExactOnlinePage,
        name: "adminExactOnline",
        path: "exact",
      },
    ],
    component: Admin,
    path: "/admin",
    redirect: "/admin/transactions",
  },
  {
    component: BarMenu,
    path: "/slides/bar",
    name: "slideBar",
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes: routes,
});

export { router };
