<template lang="pug">
.modal(@click="$emit('cancel')")
  .inner(@click.stop)
    .title {{ title }}

    .message {{ message }}

    input.input-prompt(type="text", v-model="result")

    .buttons
      input(@click="$emit('cancel')", type="button", value="Cancel")
      input.confirm(
        @click="$emit('confirm', result)",
        type="button",
        v-model="confirmText"
      )
</template>

<script setup lang="ts">
import { ref } from "@vue/reactivity";
interface PromptModalProps {
  title: string;
  message: string;
}

const result = ref("");
const confirmText = ref("OK");
</script>

<style lang="sass" scoped>
@import "../../styles/modal"

.modal
    .inner
        width: 500px

    .message
        padding: 1em 1em 0.5em 1em

    .input-prompt
        padding: 0.8em
        margin: 1em 2em 2em 2em

        font-size: 1.0em

        background: rgba(0, 0, 0, 0.05)
        border-radius: 5px
        border: 1px solid rgba(0, 0, 0, 0.1)
        outline: none
</style>
