import { Api, ApiResponse } from "./api";
import { DateRangeQueryParam, Transaction, TransactionStatus } from "../types";
import { queryString } from "../util";

export class NextTransactionResponse {
  transaction: Transaction;
}

export interface ProductCount {
  productName: string;
  count: number;
  totalPrice: number;
}

export interface ProductCountResponse {
  products: ProductCount[];
}

export class TransactionsApi extends Api {
  public async undoTransaction(
    transactionId: number
  ): Promise<ApiResponse<NextTransactionResponse>> {
    return this.post<NextTransactionResponse>(
      `/api/transactions/${transactionId}/undo/`,
      null
    );
  }

  public async finishCardTransaction(
    transactionId: number
  ): Promise<ApiResponse<NextTransactionResponse>> {
    return this.post<NextTransactionResponse>(
      `/api/transactions/${transactionId}/finish/`,
      null
    );
  }

  public async getTransactionStatus(
    transactionId: number
  ): Promise<ApiResponse<{ status: TransactionStatus; details?: string }>> {
    return this.get(`/api/transactions/card/${transactionId}/status/`);
  }

  public async adminDeleteTransaction(
    transactionId: number
  ): Promise<ApiResponse<NextTransactionResponse>> {
    return this.delete<NextTransactionResponse>(
      `/api/admin/transactions/${transactionId}/`,
      null
    );
  }
  public async accountProductCount(
    query: DateRangeQueryParam
  ): Promise<ApiResponse<ProductCountResponse>> {
    return this.get<ProductCountResponse>(
      "/api/account/transactions/count" + queryString(query)
    );
  }

  public ledgerProductCount(
    ledgerId: number,
    query: DateRangeQueryParam
  ): Promise<ApiResponse<ProductCountResponse>> {
    return this.get<ProductCountResponse>(
      `/api/admin/ledgers/${ledgerId}/transactions/count/` + queryString(query)
    );
  }
}

export const transactionsApi = new TransactionsApi();
